import React , {useContext, useEffect, useState} from 'react'
import './UserBox.css'
import {UserContext , PageContext} from '../../context/AppContext';
import ModalInterface from '../../modules/modalInterface'
import api from '../api/AxiosConfig'
import ChangeLeader from '../modal/change_leader_modal/ChangeLeader';
import utils from '../../modules/utils';
import languages from '../../language';
import ExitGroupModal from '../modal/exit-group-modal/ExitGroupModal';
import CustomSpinner from '../custon-spinner/CustomSpinner';
import Svg from '../../modules/svg';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChangeCredentialsModal from '../modal/change-credentials/ChangeCredentialsModal';
import animateCSS from '../../modules/animate';
import ModalInterval from '../modal/interval-modal/ModalInterval';

function UserBox({name , user_id , color , setForceUpdate , forceUpdate , isLeader , toggleForceUpdate , showSpinner , handleSpinner , key , index}) {
    const {userContext , setUserContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [kickPlayer , setKickPlayer] = useState(false)
    const [teamLeader , setTeamLeader] = useState()
    const [animation , setAnimation] = useState('animate__lightSpeedInRight')

    function getLogoName(){
        let firstNameLetter = String(name).charAt(0)
        let lastNameLetter = String(name).split(" ")[1].charAt(0);

        return firstNameLetter + lastNameLetter
    }

    async function getTeamLeader(){
        try {
            const response = await api.post('/game/get-team-leader', {
                user_id: userContext.userID,
                group_id: userContext.groupID
            })
            if(response.data.status === "success"){
                setTeamLeader(response.data.team_leader['team_leader'])
            }
        } catch (err){
            console.log(err);
        }
    }

    async function leaveGroup(){
        try {
            const response = await api.post('/auth/leave-group' , {
                user_id: isLeader ? user_id : userContext.userID,
                group_id: userContext.groupID
            })
            if(response.data.status == "success"){
                if(!isLeader){
                    setUserContext({
                        ...userContext,
                        firstName: '',
                        lastName: '',
                        userID: '',
                        groupID: '',
                        avatarPath: '',
                        firstLogin: 0,
                    })
    
                    setPageContext({
                        ...pageContext,
                        showUserInfo: false,
                        showLogin: true,
                        currentMinigame: 0,
                        selectedDeck: 0,
                        minigameStatus: -1,
                        selectedQuestion: null,
                        questionList: {}
                    })
                }
                ModalInterface.closePopup(pageContext)
                setAnimation('animate__lightSpeedOutRight')
                setTimeout(() =>toggleForceUpdate(), 1000)
                handleSpinner(key)
                
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function replaceLeader(){
        ModalInterface.closePopup(pageContext)
        try {
            const response = await api.post('/auth/change-team-leader', {
                group_id: userContext.groupID,
                user_id: userContext.userID,
                target_user_id: user_id
            })
            console.log(response);
            if(response.data.status == "success"){
                ModalInterface.createPopup(pageContext, null , null , null , null , <ChangeLeader toggleForceUpdate={toggleForceUpdate} target_user_id={user_id} />)
            } else {
                let body
                if(response.data.error_code == 1){
                    body = `${languages.HE.errorMessages[response.data.message][0]}
                    ${utils.convertSecondsToTime(Math.round(response.data.time))}
                    ${languages.HE.errorMessages[response.data.message][1]}`
                    ModalInterface.createPopup(pageContext , null , null , null , null , <ModalInterval 
                        title={"התראה"} 
                        body={[languages.HE.errorMessages[response.data.message][0] , languages.HE.errorMessages[response.data.message][1] ]}
                        time={Math.round(response.data.time)}
                        img={'wrong-answer'} />)
                } else {
                    if(response.data.time){
                        body = `${languages.HE.errorMessages[response.data.message][0]} ${Math.round(response.data.time)}  ${languages.HE.errorMessages[response.data.message][1]}`
                        ModalInterface.createPopup(pageContext , null , null , null , null , <ModalInterval 
                            title={"התראה"} 
                            body={[languages.HE.errorMessages[response.data.message][0] , languages.HE.errorMessages[response.data.message][1] ]}
                            time={Math.round(response.data.time)}
                            img={'wrong-answer'} />)
                    }else {
                        body = languages.HE.errorMessages[response.data.message]
                        ModalInterface.createPopup(pageContext , "שגיאה" , body , 'wrong-answer')
                    } 
                }
                
            }
        } catch (err) {
            console.log(err);
        }
    }

    function replaceLeaderHandler(){
        handleSpinner(key)
        ModalInterface.createPopup(pageContext , "התראה" ,
         "האם אתה בטוח שאתה רוצה להחליף מנהל?\nשים לב שניתן להחליף מנהל כל 48 שעות\nלאחר ההחלפה לא תוכל לבצע שום פעולת קפטן!" ,
          null ,
           [{title: "כן" , onClick: () => replaceLeader() } ,
            {title: "לא" , onClick: () => ModalInterface.closePopup(pageContext)}],
             null , false)
    }
    
    function leaveGroupHandler(){
        ModalInterface.createPopup(pageContext , "התראה" ,
         "האם אתה בטוח שאתה רוצה לצאת מהקבוצה?" ,
          null ,
           [{title: "כן" , onClick: () => leaveGroupVerify()} ,
            {title: "לא" , onClick: () => ModalInterface.closePopup(pageContext)}],
             null , false)
    }

    function leaveGroupVerify(){
        ModalInterface.createPopup(pageContext, null ,null , null , null , <ExitGroupModal exitGroup={leaveGroup} />)
    }

    function playerKickHandler(){
        ModalInterface.createPopup(pageContext , "התראה" ,
         "שימו לב כי לאחר הסרת השחקן הנוכחי, לא תוכלו להוסיף שחקן אחר במקומו, והוספת שחקן נוסף תהיה מלווה בתשלום" ,
          null ,
           [{title: "כן" , onClick: () => leaveGroupVerify()} ,
            {title: "לא" , onClick: () => ModalInterface.closePopup(pageContext)}],
             null , false)
    }

    function changeCredentialsHandler(){
        ModalInterface.createPopup(pageContext , "התראה" ,
        "האם אתה בטוח שאתה רוצה להחליף את הפרטים שלך?\n שים לב תוכל לשנות את הפרטים רק פעם אחת!" ,
         null ,
          [{title: "כן" , onClick: () => ModalInterface.createPopup(pageContext, null ,null , null , null , <ChangeCredentialsModal />)} ,
           {title: "לא" , onClick: () => ModalInterface.closePopup(pageContext)}],
            null , false)
    }

    useEffect(() => {
        getTeamLeader()
    }, [name , forceUpdate])

  return (
    <div className={`user-info-user-display animate__animated ${animation} `} id={user_id}>
        <div className='user-info-user-content'>
            <div className='user-info-user-content-right'>
                <div className='user-info-user-content-img' style={{backgroundColor: color}}>
                    <div className='user-info-user-content-img-text'>
                            {getLogoName()}
                    </div>
                </div>
                <div className='user-info-user-content-name'>
                    {name} {user_id == userContext.userID ? "(אני)" : null}
                </div>
            </div>
            <div className='user-info-user-content-left'>
            {teamLeader && ( user_id == userContext.userID && user_id != teamLeader) ?
                <div className='user-info-settings'>
                    {showSpinner ? <CustomSpinner key={key} changeCredentialsHandler={changeCredentialsHandler} leaveGroupHandler={leaveGroupHandler} isLeader={isLeader} setShowSpinner={handleSpinner} /> :<FontAwesomeIcon style={{color: '#e4e4e4' , transform: 'scale(1.5)'}} onClick={() => handleSpinner(index)} icon={faCogs}/>}
                </div>
            : null}
            {teamLeader && user_id != teamLeader && teamLeader == userContext.userID ? 
                <div className='user-info-settings'>
                    {showSpinner ? <CustomSpinner isLeader={isLeader} key={key} setShowSpinner={handleSpinner} replaceLeaderHandler={replaceLeaderHandler} playerKickHandler={playerKickHandler} /> :<FontAwesomeIcon style={{color: '#e4e4e4' , transform: 'scale(1.5)'}} onClick={() => handleSpinner(index)} icon={faCogs}/>}
                </div>
            : null}
            {user_id == teamLeader && teamLeader == userContext.userID ? 
                <div className='user-info-settings'>
                    {showSpinner ? <CustomSpinner leaderChange={true} changeCredentialsHandler={changeCredentialsHandler} key={key} setShowSpinner={handleSpinner} replaceLeaderHandler={replaceLeaderHandler} playerKickHandler={playerKickHandler} /> :<FontAwesomeIcon style={{color: '#e4e4e4' , transform: 'scale(1.5)'}} onClick={() => handleSpinner(index)} icon={faCogs}/>}
                </div>
            : null}
            
            </div>
        </div>
    </div>
  )
}

export default UserBox