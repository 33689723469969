import React, { useContext, useEffect } from 'react'
import './Modal.css'
import { PageContext } from '../../context/AppContext';
import ModalInterface from '../../modules/modalInterface';
import CustomButton from '../custom-button/CustomButton';
import Svg from '../../modules/svg';
import SoundPlayer from '../../audio/SoundPlayer';
import soundHelper from '../../audio/SoundHelper';
import utils from '../../modules/utils';


export default function Modal({ img, title, body, buttons, isCloseButton, visible, children, style }) {
  const { pageContext } = useContext(PageContext)
  const styleSheet = {
    display: visible ? 'flex' : 'none',
    ...style,
  }

  const styleSheetImg = {
    display: img ? 'block' : 'none'
  }

  function handleClose() {
    ModalInterface.closePopup(pageContext)
  }

  return (

    <main style={styleSheet} className='modal-container'>

      <div className='modal-content'>
        {children ?
          <div className='modal-child'>
            {children}
            <div className='modal-bottom'>
            {buttons ?
                <div className='buttons-container'>
                  <div>
                    {buttons.map((b, i) => {
                      return (
                        <div key={`modal-button-${i}`}>
                          <CustomButton
                            onClick={b.onClick}
                            width={"300px"}
                            content={b.title}
                            backgroundGradiant={{ top: "rgba(21, 138, 110, 1)", bottom: "rgba(3, 62, 81, 1)" }}
                            margin={'1% 0px 0px 0px'}
                            fontColor={"#fff"}
                            oneTime={!b.disabled}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
                : null}
            </div>
            </div>
          
          :
          <div>
            {img ?
              <div className='modal-top' style={style && style.top ? style.top : null}>

                <div className='modal-top-img' style={styleSheetImg}>
                  <Svg fileName={img} />
                </div>

              </div>
              : null}
            <div className='modal-middle' style={style}>
              <div className='modal-title-container'>
                <h2>{title}</h2>
              </div>
              <div className='modal-body-container' >
                <h4>{body}</h4>
              </div>
              
            </div>
            <div className='modal-bottom'>
            {buttons ?
                <div className='buttons-container'>
                  <div>
                    {buttons.map((b, i) => {
                      return (
                        <div key={`modal-button-${i}`}>
                          <CustomButton
                            onClick={b.onClick}
                            width={"300px"}
                            content={b.title}
                            backgroundGradiant={{ top: "rgba(21, 138, 110, 1)", bottom: "rgba(3, 62, 81, 1)" }}
                            margin={'1% 0px 0px 0px'}
                            fontColor={"#fff"}
                            oneTime={!b.disabled}
                        /></div>
                      )
                    })}
                  </div>
                </div>
                : null}
              {isCloseButton ? <CustomButton content={"סגור"} zIndex={100} onClick={handleClose} backgroundGradiant={{ top: "rgba(21, 138, 110, 1)", bottom: "rgba(3, 62, 81, 1)" }} margin={'10% 0px 0px 0px'}  ></CustomButton> : null}
            </div>
          </div>
        }
      </div>
    </main >
  )
}
