import React, { useState, useRef, useEffect } from 'react';
import './PageScanner.css';
import QRCodeReader from '../../components/qr-code-reader/QRCodeReader';

function PageScanner() {

  useEffect(() => {
    window.location.hash = 'scanner'
  },[])
  return (
    <div className="scanner-container">
      <QRCodeReader />
    </div>
  );
}

export default PageScanner;
