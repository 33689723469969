import React from 'react'
import './OrientationModal.css'
import Svg from '../../../modules/svg'
import languages from '../../../language'

function OrientationModal() {
  return (
    <div className='orientation-container'>
        <div className='orientation-content'>
            <Svg fileName={"logo_main"}/>
            <p>{languages.HE.gameTexts['orientation text']}</p>
        </div>
        
    </div>
  )
}

export default OrientationModal