import React, { useEffect, useRef } from 'react';
import CorrectAnswerSE from './sounds/correct.mp3';
import ClickSE from './sounds/click.mp3'
import ModalSE from './sounds/info.mp3'
import ErrorSE from './sounds/error.mp3'
import LaughSE from './sounds/ha ha ha.mp3'
import CoinsSE from './sounds/coins.mp3'
import BackgroundSE from './sounds/background.mp3'

const SoundPlayer = ({soundContext , setSoundContext}) => {
  const audioRef = useRef(null);
  const bgAudioRef = useRef(null)
  const { playBackground, playClick, playConfetti, playError, playLaugh, playAhoy, playCoins } = soundContext;

  const playSoundEffect = () => {
    if(!soundContext.allowUISound){
      return
    }
    if(playClick){
      if(audioRef.current && !audioRef.current.paused){
        return
      }
    }

    if (playClick) {
      audioRef.current.src = ClickSE;
    } else if (playConfetti) {
      audioRef.current.src = CorrectAnswerSE;
    } else if (playError) {
      audioRef.current.src = ErrorSE;
    } else if (playLaugh) {
      audioRef.current.src = LaughSE;
    } else if (playAhoy) {
      audioRef.current.src = ModalSE;
    } else if (playCoins) {
      audioRef.current.src = CoinsSE;
    } else {
      return
    }

    audioRef.current.volume = soundContext.volume
    audioRef.current.play();
  };

  function adjustSound(){
    bgAudioRef.current.volume = soundContext.volume
    audioRef.current.volume = soundContext.volume
  }

  function playBackgroundSE(){
    if(bgAudioRef){
      if(!bgAudioRef.current.paused){

        return
      }
    }
    if (playBackground) {
      bgAudioRef.current.src = BackgroundSE;
    } else {
      return
    }

    bgAudioRef.current.volume = soundContext.volume
    bgAudioRef.current.play()
  }

  const handleAudioEnded = () => {
    audioRef.current.src = null;
    if (playClick) {
      setSoundContext(prev => ({
        ...prev,
        playClick: false
      }))
    } else if (playConfetti) {
      setSoundContext(prev => ({
        ...prev,
        playConfetti: false
      }))
    } else if (playError) {
      setSoundContext(prev => ({
        ...prev,
        playError: false
      }))
    } else if (playLaugh) {
      setSoundContext(prev => ({
        ...prev,
        playLaugh: false
      }))
    } else if (playAhoy) {
      setSoundContext(prev => ({
        ...prev,
        playAhoy: false
      }))
    } else if (playCoins) {
      setSoundContext(prev => ({
        ...prev,
        playCoins: false
      }))
    } else {
      return
    }
  };

  const handleBGEnded = () => {
    bgAudioRef.current.currentTime = 0;
    bgAudioRef.current.play();
  
};

function stopBackgroundSE(){
  if(bgAudioRef.current.src && !playBackground){
    bgAudioRef.current.pause()
  }
 
}

function stopSoundEffect(){
 
}


  useEffect(() => {
    playBackgroundSE()
    playSoundEffect()
    stopBackgroundSE()
    adjustSound()
  },[soundContext])

  return (
    <div>
      <audio ref={bgAudioRef} onEnded={handleBGEnded}></audio>
      <audio ref={audioRef} onEnded={handleAudioEnded} />
    </div>
  );
};

export default SoundPlayer;
