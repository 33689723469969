import React, { useEffect, useRef } from 'react'
import './SoundMenu.css'
import Svg from '../../modules/svg'

// const [soundContext , setSoundContext] = useState({
//     allowSound: false,
//     volume: 50,
//     showMenu: false,
//     allowBackground: false,
//     allowUISound: true,
//     playBackground: false,
//     playClick: false,
//     playConfetti: false,
//     playError: false,
//     playLaugh: false,
//     playAhoy: false,
//     playCoins: false
//   })



function SoundMenu({soundContext , setSoundContext, volumeRef}) {
    const menuRef = useRef(null)
    
    function handleCloseButton(){
        setSoundContext(prev => ({
          ...prev,
          showMenu: !soundContext.showMenu
        }))
      
        
    }
    function hanldeVolumeChange(event){
        setSoundContext({
            ...soundContext,
            volume: event.target.value / 100
        })
    }

    function handleCheckBox(type){
      if(type == "bg"){
        setSoundContext({
          ...soundContext,
          allowBackground: !soundContext.allowBackground,
          playBackground: !soundContext.playBackground
        })
      } else {
        setSoundContext({
          ...soundContext,
          allowUISound: !soundContext.allowUISound
        })
      }
    }

    
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!menuRef.current.contains(event.target) && !volumeRef.current.contains(event.target)) {
        handleCloseButton()
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className='sound-menu-container' ref={menuRef}>
        <div className='sound-menu-close-button'>
            <Svg fileName={'x'} onClick={() => handleCloseButton()}/>
        </div>
        <div className='sound-menu-content'>
        <h2 className='sound-menu-header'>הגדרות שמע</h2>
        <div className='check-box-container'>
            <div className='cb-holder'>
                <input className='checkbox' type={"checkbox"} onChange={() => handleCheckBox("bg")} checked={soundContext.allowBackground}></input>
                <label>הפעל מוזיקת רקע</label>
            </div>
            <div className='cb-holder'>
                <input className='checkbox' type={"checkbox"} onChange={() => handleCheckBox("general")} checked={soundContext.allowUISound}></input>
                <label>הפעל סאונדים כללים</label>
            </div>
            <div className='cb-holder' style={{justifyContent: 'center'}}>
            <input type='range' min={0} max={100} value={soundContext.volume * 100} onChange={hanldeVolumeChange}></input>
            {soundContext.volume > 0 ? <i className="fa fa-volume-up"></i> : <i className="fa fa-volume-off"></i>}
        </div>
        
            
            
        </div>
        </div>
        
        
    </div>
  )
}

export default SoundMenu