import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Accessibility } from 'accessibility';
import { options } from './components/accessibility/Accessibility';


window.addEventListener('load', function () {
  new Accessibility({ labels: options.labels, modules: options.modules, session: options.session });
}, false);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register()
