import React , {useEffect , useState , useContext}from 'react'
import './PageCoinBank.css'
import api from '../../components/api/AxiosConfig'
import Svg from '../../modules/svg'
import { UserContext , PageContext } from '../../context/AppContext'
import languages from '../../language'
import CoinCard from '../../components/card/coincard/CoinCard'
import Card from '../../components/card/Card'
import CoinBankHistory from '../../components/coinbankhistory/CoinBankHistory'
import BG from '../../images/coin-bank-card.svg'
import ProgressInterface from '../../modules/progressInterface'
import CustomButton from '../../components/custom-button/CustomButton'
import QRCodeReader from '../../components/qr-code-reader/QRCodeReader'
import settings from '../../settings'

function PageCoinBank({show}) {
    const {userContext , setUserContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [cardAdjustment , setCardAdjustment] = useState({})
    const [purchuseHistory , setPurchuseHistory] = useState({})
    const [forceUpdate , setForceUpdate] = useState(1)
    const [showProgressBar , setShowProgressBar] = useState(false)
    const [coinsBought, setCoinsBought] = useState('');
    
    async function getPurchuseHistory(){
      
      try {
        setShowProgressBar(true)
        const response = await api.post('bank/get-purchuse-history', {
          user_id: userContext.userID,
          group_id: userContext.groupID
        })
        if(response.data.status == 'success'){
          setPurchuseHistory(response.data.history)
        }
        setShowProgressBar(false)
      } catch (err) {
        console.log(err);
      }
    }

    async function getGroupBalance(){
      try {
        const response = await api.post('bank/get-coin-balance', {
          user_id: userContext.userID,
          group_id: userContext.groupID
        })
        
        if(response.data.status == 'success'){
          setUserContext(prev => ({
            ...prev,
            coinBalance: response.data.balance
          }))
        }
      } catch (err) {
        console.log(err);
      }
    }

    function handleForceUpdate(){
      if(forceUpdate == 1){
        setForceUpdate(0)
      } else {
        setForceUpdate(1)
      }
    }

    function handleSize(){
      if(window.innerWidth > 480){
        setCardAdjustment({
          width: "300px",
          height: "400px"
        })
      } else {
        setCardAdjustment({
          width: "calc(50% - 5px)",
          height: "250px"
        })
      }
    }

    function fixDateDisplay(dateTimeString){
      const date = new Date(dateTimeString);

      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);

      const formattedDateTimeString = hour + ':' + minute + ' ' + year + '-' + month + '-' + day
      return formattedDateTimeString
    }

    function handleScanQRCode(){
      setPageContext({
        ...pageContext,
        showCoinBank: false,
        showScanner: true
      })
    }

    useEffect(()=>{
      window.location.hash = 'coins'
    }, [])
    
    useEffect(() => {
      handleSize()
      getGroupBalance()
      getPurchuseHistory()
    },[userContext.coinBalance, coinsBought])
  return (
    <div className='coin-bank-container'>
        <div className='coin-bank-container-top'>
            <h1>מטבעות</h1>
            
            <span>{languages.HE.gameTexts['coins text'][0]}</span>
            <br></br>
            <span>{languages.HE.gameTexts['coins text'][1]}</span>
            <br></br>
            <span>{languages.HE.gameTexts['coins text'][2]}</span>
            <br></br>
            <span>{languages.HE.gameTexts['coins text'][3]}</span>
        </div>
        <div className='coin-bank-container-middle'>
          <Card width={cardAdjustment.width} height={cardAdjustment.height} boxShadow={"none"} backgroundSize={'contain'} backgroundImage={BG} position={'relative'} animation={'animate__fadeInRight'}>
            <CoinCard price={"₪2"} text={"מטבע אחד"} img={'coin-xsmall'} coins={1} />
          </Card>
          <Card width={cardAdjustment.width} height={cardAdjustment.height} boxShadow={"none"} backgroundSize={'contain'} backgroundImage={BG} position={'relative'} animation={'animate__fadeInRightBig'}>
            <CoinCard price={"₪40"} text={"חבילה של 20 מטבעות בונוס 2 מטבעות 22 סך הכל"} img={'coin-small'} coins={22} />
          </Card>
          <Card width={cardAdjustment.width} height={cardAdjustment.height} boxShadow={"none"} backgroundSize={'contain'} backgroundImage={BG} position={'relative'} animation={'animate__fadeInLeftBig'}>
            <CoinCard price={"₪100"} text={"חבילה של 50 מטבעות בונוס 10 מטבעות 60 סך הכל"} img={'coin-medium'} coins={60} />
          </Card>
          <Card width={cardAdjustment.width} height={cardAdjustment.height} boxShadow={"none"} backgroundSize={'contain'} backgroundImage={BG} position={'relative'} animation={'animate__fadeInLeft'} >
            <CoinCard price={"₪160"} text={"חבילה של 80 מטבעות בונוס 20 מטבעות 100 סך הכל"} img={'coin-large'} coins={100} />
          </Card>
        </div>
        <div className='coin-bank-container-bottom-header'>
          <h3 >הסטורית קניות</h3>
        </div>
        <div className='coin-bank-container-bottom'>
          {showProgressBar ? 
          <div className='coin-bank-custom-progress'>
            <div className='coin-bank-circle-progress'></div>
          </div>
          : 
          <table>
              <thead>
                <tr>
                  <th>שעה ותאריך</th>
                  <th>פעולה</th>
                  <th>מטבעות</th>
                  <th>מחיר</th>
                </tr>
              </thead>
              <tbody className='coin-bank-history'>
                {purchuseHistory ? Object.keys(purchuseHistory).reverse().map((item , index) => {
                  return(
                    <CoinBankHistory key={index} time={fixDateDisplay(purchuseHistory[item].last_action_date)} action={purchuseHistory[item].last_action} amount={purchuseHistory[item].amount} price={purchuseHistory[item].price} /> 
                  )
                }): null}
                    
              </tbody>
            </table>
          }
        </div>
          <div className='coin-bank-scan-qr-button'>
          {window.innerWidth < 480 ? <CustomButton width={"calc(90% - 10px)"} onClick={() => handleScanQRCode()} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}} content={"סרוק קוד QR"} /> : null}

          </div>
    </div>
  )
}

export default PageCoinBank