import React , {useEffect , useState , useContext} from 'react'
import {PageContext , UserContext} from '../../context/AppContext';
import api from '../api/AxiosConfig'
import BGA from '../../images/minigame-bg/bg-brown.svg'
import CollectionCard from '../../components/card/collectioncard/CollectionCard'
import './CardDeck.css'
import ProgressInterface from '../../modules/progressInterface';
import animateCSS from '../../modules/animate';
import ModalInterface from '../../modules/modalInterface';
import languages from '../../language';

function CardDeck({currentMinigame , onClick}) {
    const {pageContext , setPageContext } = useContext(PageContext)
    const {userContext} = useContext(UserContext)
    const [details , setDetails] = useState(null)

    async function getMinigameDetails(){
      if(pageContext.currentMinigame == 3){
        return
      }
        try {
          ProgressInterface.showProgressBar(setPageContext, pageContext)
          const response = await api.post('/game/get-minigame-details', {
            minigame_id: pageContext.currentMinigame
          }
          )
          .then((response) => {
            if(response.data.status == "success"){
              setDetails(response.data.details)
            } else {
              ModalInterface.createPopup(pageContext , "התראה" , languages.HE.errorMessages[response.data.message] , 'wrong-answer')
            }
          })
          .finally(() => {
            ProgressInterface.hideProgressBar(setPageContext, pageContext)
          })
           
        } catch (err) { 
          console.log(err);
        }
        ProgressInterface.hideProgressBar(setPageContext, pageContext)
      }


      async function handleCollectionClick(index){
        setPageContext({
            ...pageContext,
            showSpread: true,
            selectedDeck: index,
        })
      }


      useEffect(() => {
        getMinigameDetails()
      },[currentMinigame])

  return (
    <>
        {currentMinigame != 0 &&  details ?
       Object.keys(details).map((index , deck) => {
            return(
                <div key={index}>
                    <CollectionCard onClick={() => handleCollectionClick(details[index].id)} title={details[index].title} bg={BGA} order={deck} color={details[index].deck_color} ></CollectionCard>
                </div>
            )
        })
        : <div className='minigame-closed-alert'>{languages.HE.alerts['minigame close']}</div>
        }
        
    </>
  )
}

export default CardDeck