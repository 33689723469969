const soundHelper = {
    
    playSound: function playSound(setSoundContext,playClick = false , playConfetti = false , playError = false , playLaugh = false , playAhoy = false , playCoins = false){
        try {
            setSoundContext(prev => ({
                ...prev,
                playClick: playClick,
                playConfetti: playConfetti,
                playError: playError,
                playLaugh: playLaugh,
                playAhoy: playAhoy,
                playCoins: playCoins
            })) 
        } catch (err) {
            console.log(err);
        }
        
    }
}

export default soundHelper