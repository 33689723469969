import React, { useEffect, useState , useContext} from 'react'
import { AnimContext } from '../context/AppContext';
import './raincoins.css'

function Raincoins({display}) {
    const {animContext , setAnimContext} = useContext(AnimContext)
    function gimmick(el, time) {
        try {
            var exists = document.getElementById('gimmick');
            if (exists) {
                exists.parentNode.removeChild(exists);
                return false;
            }
        } catch (err) {
            console.log(err);
        }
    
        var element = document.querySelector(el);
        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d'),
            focused = false;
    
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        canvas.id = 'gimmick';
        canvas.style.position = 'fixed';
        canvas.style.zIndex = 0;
        canvas.style.top = '0px';
        canvas.style.left = '0px';
    
        var coin = new Image();
        coin.src = 'http://i.imgur.com/5ZW2MT3.png';
        // 440 wide, 40 high, 10 states
        coin.onload = function () {
            element.appendChild(canvas);
            focused = true;
            drawloop();
        };
        var coins = [];
    
        function drawloop() {
            if (focused) {
                requestAnimationFrame(drawloop);
            }
    
            ctx.clearRect(0, 0, canvas.width, canvas.height);
    
            if (Math.random() < 0.3) {
                coins.push({
                    x: Math.random() * canvas.width | 0,
                    y: -50,
                    dy: 3,
                    s: 0.5 + Math.random(),
                    state: Math.random() * 10 | 0
                });
            }
            var i = coins.length;
            while (i--) {
                var x = coins[i].x;
                var y = coins[i].y;
                var s = coins[i].s;
                var state = coins[i].state;
                coins[i].state = (state > 9) ? 0 : state + 0.1;
                coins[i].dy += 0.3;
                coins[i].y += coins[i].dy;
    
                ctx.drawImage(coin, 44 * Math.floor(state), 0, 44, 40, x, y, 44 * s, 40 * s);
    
                if (y > canvas.height) {
                    coins.splice(i, 1);
                }
            }
        }
    
        setTimeout(() => {
            focused = false;
            element.removeChild(canvas);
            setAnimContext(prev => ({
                ...prev,
                showCoinRain: false
            }))
        }, time);
    }
    
    useEffect(() => {
        if (display) {
            const time = 4000; // Set the time in milliseconds
            gimmick('body', time);
        }
    }, [display]);
    
  return (
    <div></div>
    
  )
}

export default Raincoins