import React , {useState , useContext, useEffect} from 'react'
import {PageContext , UserContext} from '../../../context/AppContext';
import utils from '../../../modules/utils';
import api from '../../api/AxiosConfig'
import CustomButton from '../../custom-button/CustomButton';
import './ChangeLeader.css'
import ModalInterface from '../../../modules/modalInterface';
import languages from '../../../language';

function ChangeLeader(props) {
    const [formData , setFormData] = useState({})
    const {userContext , setUserContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const fullnameString = utils.generateRandomString(1)
    const [buttonAdjustments, setButtonAdjustments] = useState({width: "150px"})
    const [error , setError] = useState(null)

    function handleChange(event){
        if(event.target.name == "leader_code" || event.target.name == "target_code"){
            setFormData({
                ...formData ,
                [event.target.name]: event.target.value
            })
        } else {
            setFormData({
                ...formData,
                fullnameString: event.target.value
            })
        }
        
    }

    async function handleLeaderChange(event){
        try {
            const response = await api.post('/auth/change-team-leader-verify' , {
                user_id: userContext.userID,
                target_user_id: props.target_user_id,
                group_id: userContext.groupID,
                leader_code: formData.leader_code,
                target_code: formData.target_code,
                fullname: formData.fullnameString
            })
            if(response.data.status == "success"){
                ModalInterface.createPopup(pageContext, "התראה" , "הקפטן הוחלף בהצלחה!" , "right-answer")
                    props.toggleForceUpdate()              
            } else {
                setError(languages.HE.errorMessages[response.data.message])
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function handleLeaderChagneCancel(){
        try {
            const response = await api.post('/auth/cancel-change-team-leader' , {
                group_id: userContext.groupID,
                user_id: userContext.userID,
                target_user_id: props.target_user_id,
            })
            if(response.data.status == "success"){
                ModalInterface.closePopup(pageContext)
            } else {
                setError(languages.HE.errorMessages[response.data.message])
            }
        } catch (err) {
            console.log(err);
        }
    }

    function buttonsControl(){
        ModalInterface.addButtons(pageContext, [{"title": "החלף" , "disabled": true , onClick: () => handleLeaderChange()}, {"title": "ביטול" , "disabled": true , onClick: () => handleLeaderChagneCancel()}])
    }

    useEffect(() => {
        buttonsControl()
    },[formData])

  return (
    <div className='change-leader-container'>
        <div className='change-leader-exit-button' onClick={() => handleLeaderChagneCancel()}>X</div>
        <div className='change-leader-top'>
            <p>החלף קפטן</p>
            <p>נא להזין את קוד האימות שנשלח לקפטן ולשחקן המיועד</p>
        </div>
        <div className='change-leader-error'>
            {error}
        </div>
        
        <form className='change-leader-form'>
            <input className='change-leader-form-input' name={'leader_code'} placeholder={"קוד אימות קפטן"} onChange={handleChange} required></input>
            <input className='change-leader-form-input' name={'target_code'} placeholder={"קוד אימות שחקן"} onChange={handleChange} required></input>
            <input type={'text'} name={fullnameString} style={{display: "none"}} onChange={handleChange}></input>

        </form>
        <div className='change-leader-bottom'>
            {/* <CustomButton width={buttonAdjustments.width} content={"החלף"} onClick={() => handleLeaderChange()} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}} ></CustomButton>
            <CustomButton width={buttonAdjustments.width} content={"ביטול"} onClick={() => handleLeaderChagneCancel()} backgroundGradiant={{top: "rgba(156, 122, 122, 1)" , bottom: "rgba(74, 32, 32, 1)"}} ></CustomButton> */}
        </div>
    </div>
  )
}

export default ChangeLeader