import React from 'react'
import './MinigameCard.css'
import Svg from '../../../modules/svg'

export default function MinigameCard(props) {
  return (
    <div className='minigame-card-content'>
      {props.done && props.type != "junior" ?
      <div className='minigame-card-img'>
        <Svg fileName='question_done' />
      </div> 
       : null}
      <div className='minigame-card-location'>
        <Svg fileName='card-divider'></Svg>
        <h2>{props.location}</h2>
        <Svg fileName='card-divider'></Svg>
        {props.riddle ? 
        <h4>
          {props.riddle}
        </h4> :
        null}
      </div>
      <div className='minigame-card-date'>
          {props.startdate ? <p>start date</p> : null}
      </div>
    </div>
  )
}
