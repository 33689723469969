const languages = {
    HE: {
        errorMessages: {
            "not a team leader": "משתמש אינו קפטן הקבוצה!",
            "max hints": "השתמשת כבר בכל הרמזים שלך!",
            "wrong fields": "אחד מהשדות אינו נכון!",
            "user exists": "המשתמש כבר קיים במערכת!",
            "user doesnt exists": "המשתמש לא קיים במערכת!",
            "wrong phone": 'מספר הטלפון אינו תקין!',
            "wrong authcode": 'קוד האימות אינו נכון!',
            "expired authcode": 'קוד האימות פג תוקף!',
            "file type upload error": 'הקובץ שהועלה אינו מהסוג הנכון!',
            "file size small": 'גודל הקובץ קטן מידי!',
            "file upload successfully": 'הקובץ הועלה בהצלחה!',
            "wrong answer format": 'יש סימן לא נכון בתשובה',
            "wrong answer": 'תשובה שגויה!',
            "cant view task": 'אינך יכול לצפות בשאלה הזאת!',
            "must finish previous minigame": 'אינך יכול להמשיך למשחק הבא בלי הסיימת את הקודם!',
            "minigame didnt start": 'המשחק עדיין לא התחיל!',
            "no_captcha_filled" : 'יש לסמן אני לא רובוט על מנת להמשיך',
            'invalid_auth_code': "הקוד שהזנתם לא תקין , נסו שוב",
            'auth_blocked': "נחסמת מכניסה למשחק! אנא נסה שוב מאוחר יותר",
            'must wait before sending another code': ["עלייך להמתין " , " שניות לפני כניסה חוזרת!"],
            'must finish prevoius minigame': 'עלייך לסיים את השלב הקודם לפני שתוכל להמשיך הלאה!',
            'minigame didnt start': 'עלייך להמתין עד שהמשחק יפתח!',
            'db error': 'שגיאה במערכת!',
            'non-sufficient funds': 'אין לך מספיק מטבעות!',
            'cant change team leader timestamp': ['עלייך להמתין' , 'לפני החלפת קפטן!'],
            "must wait before attempt chaning leader again": [" עלייך להמתין" , " שניות לפני ניסיון חוזר!"],
            "qr code doesnt exists": "הברקוד לא קיים במערכת!",
            "qr code isnt active": "הברקוד נחסם משימוש!",
            "qr code has already been used": "הקבוצה שלך כבר השתמשה בברקוד הזה!",
            "you used all your skips": "השתמשת בכל הדילוגים!",
            "you cant change your credentials again": 'אינך יכול לשנות את פרטי המשתמש שלך שוב!',
            "you must finish the question before getting the photo details": 'עלייך לענות על השאלה על מנת לצפות במידע זה!',
            "blacklist name": "שם הקבוצה אינו תקין!",
            "game is unavailable": "המשחק סגור כרגע! אנא נסו שנית במועד מאוחר יותר",
            'unknown coin amount': "כמות מטבעות לא ידועה!",
            "the team is full": "הקבוצה מלאה!",
            "must wait before trying to register another user": "עלייך להמתין לפני נסיון הרשמה נוסף",
            "users is not active": "השחקן אינו פעיל! אנא צור קשר על ההנהלה",
            "access denied": "המשחק סגור!",
            "target player doesnt have phone": "לשחקן המיועד אין מספר טלפון רשום במערכת!",
            "wrong auth code": "הקוד שגוי אנא נסה שנית",
            "User already online!": "זיהינו שהתחברת ממכשיר נוסף.  ניתן להתחבר רק ממכשיר אחד בכל רגע נתון",
            "User not authenticated!": "המפתח שלך פג תוקף אנא התחבר מחדש",
            "update required": "דרוש עדכון למשחק, נא לסגור את החלון / אפליקציה ולפתוח מחדש",
            "group already answered this question": "הקבוצה כבר ענתה על השאלה!"
        },

        alerts: {
            'user insert successfuly': "השחקן הצטרף לקבוצה בהצלחה!",
            "minigame close": "המשחק סגור כרגע!"
        },

        gameTexts: {
            'welcome page paragraph': `קפטן סם מחכה ומצפה שתעזרו לו להשיב את האוצר האבוד. הוא מאחל לכם בהצלחה! על עזרתכם הוא מוכן לתת לקבוצה שתמצא אותו 100,000 ש"ח, או את אחד מפרסי המשנה בשאר המשימות.`,
            'adult info': "המשחק הראשי אל האוצר הוא בעל 3 שלבים.  הקבוצה הראשונה להגיע לפתרון שלושת השלבים תוכל לזכות בפרס הראשי. השלבים הם ברמת קושי עולה. רק לאחר פתרון השלב הראשון ניתן יהיה  לעבור לשלב השני. השלב השני יפתח מיד לאחר סיום השלב הראשון. ולאחר פתרון השלב השני ניתן יהיה לעבור מיד לשלב השלישי. תמיד תוכלו לחזור ולבחור במסלולי הג'וניור אם תרצו משחק ממוקד לפי אזור מסויים וקל יותר.",
            'junior info': " 3 מסלולים קצרים בשלושה אזורים ספציפיים בתל אביב. במסלולים אלו החידות מהנות וברמת קושי קלה מאוד. המסלולים מותאמים למשפחות עם ילדים. שימו לב כי בסופם של מסלולים אלו לא ניתן לזכות בפרס הגדול, אך תמיד תוכלו לחזור לשחק במשחק הראשי.",
            'coins text': ['שימוש במטבעות משחק יהיה אפשרי רק בשלב הראשון.' , ' שלב 1:  רמז - 5 מטבעות משחק. דלג על שאלה - 10 מטבעות משחק.' , ' שלב 2: רמז - 10 מטבעות משחק.' , ' שלב 3: לא ניתן לבצע דילוג על שאלה או לבקש רמז כלל.'],
            'orientation text': 'האפליקציה זמינה רק במצב אנכי, יש להחליף את זווית המכשיר',
            'photo_details': ['כאשר אתם מצלמים, שימו לב לדגשים שצריכים להיות בתמונה:' , '1. וודאו שהאובייקט שביקשנו לראות נמצא בתמונה.' , '2. וודאו שיד אחת מכסה את העין - כמו רטייה של פיראט.' , '3. וודאו כי היד השניה מונפת כמו קרס וו (יד) של פיראט.' , '4. וודאו שכל הגוף נוכח בתמונה, כולל רגליים.' , '5. וודאו שהתמונה ברורה, ולא מטושטשת.' ,"מה צריך לצלם"],
        }
    }
}

export default languages