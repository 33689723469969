import { validate } from "uuid"
import settings from "../settings"

const fieldValidate = {
  validateAnswer: async function validateAnswer(answer, settings){
    let answerLen = checkLength(answer)
    if(answerLen > settings.answersRegex_MAX_ANSWER_LENGTH || answerLen < settings.answersRegex_MIN_ANSWER_LENGTH){
      return {status: false , error: "string length issue!"}
    }
    if(!settings.answersRegex_allowHebrewLetters && isContainHebrew(answer)){
      return {status: false , error: "contains hebrew!"}
    }
    if(!settings.answersRegex_allowEnglishLetters && isContainEnglish(answer)){
      return {status: false , error: "contains english!"}
    }
    if(!settings.answersRegex_allowSpacesLetters && isContainSpaces(answer)){
      return {status: false , error: "contains spaces!"}
    }
    if(!settings.answersRegex_allowNumbersLetters && isContainNumbers(answer)){
      return {status: false , error: "contains numbers!"}
    }
    if(!settings.answersRegex_allowSpeicalLetters && isContainSpecialSigns(answer)){
      return {status: false , error: "contains special signs!"}
    }
    if(!settings.answersRegex_allowApostropheSign && isContainApostropheSign(answer)){
      return {status: false , error: "contains apostrophe sign!"}
    }
    if(!settings.answersRegex_allowQuoteSign && isContainQuotes(answer)){
      return {status: false , error: "contains quotes!"}
    }
    if(!settings.answersRegex_allowLatinSpecialLetters && isContainLatinLetters(answer)){
      return {status: false , error: "contains special latin letters!"}
    }
    return {status: true}
  }, 

  validateGroupName: async function validateGroupName(group_name,settings){
    let groupNameLen = checkLength(group_name)
    if(!group_name){
      return {status: false , error: "no name!"}
    }
    if(groupNameLen > settings.groupLimits_groupNameLimit_MAX_NAME_LENGTH|| groupNameLen < settings.groupLimits_groupNameLimit_MIN_NAME_LENGTH){
      return {status: false , error: "string length issue!"}
    }
  
    if(!settings.groupLimits_groupNameLimit_allowHebrewLetters && isContainHebrew(group_name)){
      return {status: false , error: "contains hebrew!"}
    }
    if(!settings.groupLimits_groupNameLimit_allowEnglishLetters && isContainEnglish(group_name)){
      return {status: false , error: "contains english!"}
    }
    if(!settings.groupLimits_groupNameLimit_allowSpacesLetters && isContainSpaces(group_name)){
      return {status: false , error: "contains spaces!"}
    }
    if(!settings.groupLimits_groupNameLimit_allowNumbersLetters && isContainNumbers(group_name)){
      return {status: false , error: "contains numbers!"}
    }
    if(!settings.groupLimits_groupNameLimit_allowSpeicalLetters && isContainSpecialSigns(group_name)){
      return {status: false , error: "contains special signs!"}
    }
    if(!settings.groupLimits_groupNameLimit_allowApostropheSign && isContainApostropheSign(group_name)){
      return {status: false , error: "contains apostrophe sign!"}
    }
    if(!settings.groupLimits_groupNameLimit_allowQuoteSign && isContainQuotes(group_name)){
      return {status: false , error: "contains quotes!"}
    }
    if(!settings.groupLimits_groupNameLimit_allowLatinSpecialLetters && isContainLatinLetters(group_name)){
      return {status: false , error: "contains special latin letters!"}
    }
    return {status: true}
  },

  validateNewUser: function validateNewUser(userData){
    if(!userData.first_name || !userData.last_name){
      return {status: false , message: "נא למלא את השם המלא של השחקן!"}
    }

    let fullnameFlag = validateFullname(userData.first_name , userData.last_name)
    let phoneFlag = valdiatePhonenumber(userData.phone)
    let emailFlag = validateEmail(userData.email)

    if(!fullnameFlag){
      return {status: false , message: "שגיאה בשם השחקן"}
    }

    if(userData.phone){
      if(!phoneFlag){
        return {status: false , message: "שגיאה במספר הטלפון"}
      }
    }
    
    if(userData.email){
      if(!emailFlag){
        return {status: false , message: "שגיאה בדואר אלקטרוני"}
      }
    }
    
    return {status: true}
  },

  validateUserCredentials: function validateUserCredentials(phonenumber , email){
    let phoneFlag = false
    let emailFlag = false
    if(phonenumber){
        if(!valdiatePhonenumber(phonenumber)){
            phoneFlag = true
        }
    }
    if(email){
        if(!validateEmail(email)){
            emailFlag = true
        }
    }
    if(phoneFlag && emailFlag){
        return {status: false , message: "שגיאה בטלפון ובדואר אלקטרוני"}
    } else if(phoneFlag){
        return {status: false , message: "שגיאה במספר הטלפון"}
    } else if(emailFlag){
        return {status: false , message: "שגיאה בדואר אלקטורני"}
    }

    return {status: true , message: ""}
  }
}

  function validateFullname(first_name , last_name){
    let firstNameFlag = false;
    let lastnameFlag = false;
    let firstNameLen = checkLength(first_name)
    let lastNameLen = checkLength(last_name)
    let pattern = /^[A-Za-zא-ת'" - `]+$/;

    if (firstNameLen >= settings.registerLimits_NAME_MIN_LEN && firstNameLen <= settings.registerLimits_NAME_MAX_LEN) {
      firstNameFlag = true;
    } else {
      firstNameFlag = false;
    }
    if (lastNameLen >= settings.registerLimits_NAME_MIN_LEN  && lastNameLen <= settings.registerLimits_NAME_MAX_LEN) {
      lastnameFlag = true;
    } else {
      lastnameFlag = false;
    }
    if (firstNameFlag && lastnameFlag && pattern.test(first_name) && pattern.test(last_name)) {
      return true;
    }
    return false;
  }
  
  function valdiatePhonenumber(phonenumber){
    let phoneLen = checkLength(phonenumber)
    const numberRegex = /^[0-9]+$/;
    if(!numberRegex.test(phonenumber)){
      return false
    }
    if(phoneLen != 10){
      return false
    }
    if(phonenumber[0] != 0 && phonenumber[1] != 5){
      return false
    }
    return true
  }

  function validateEmail(email){
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  function isContainHebrew(str){
    const hebrewRegex = /[^\s\n]*[\u0590-\u05FF][^\s\n]*/;
    return hebrewRegex.test(str);
  }
  
  function isContainEnglish(str){
    const englishRegex = /[a-zA-Z]/;
    return englishRegex.test(str);
  }
  
  function isContainNumbers(str){
    const numberRegex = /\d/
    return numberRegex.test(str)
  }
  
  function isContainSpecialSigns(str){
    const specialRegex = /[!@#$%^&*()_+\-=;]/;
    return specialRegex.test(str)
  }
  
  function isContainApostropheSign(str){
    const apostropheRegex = /['\u2019]/;
    return apostropheRegex.test(str);
  }
  
  function isContainSpaces(str){
    if(String(str).includes(' ')){
      return true
    } else {
      return false
    }
  }
  
  function isContainQuotes(str){
    const quoteRegex = /["'`]/;
    return quoteRegex.test(str);
  }
  
  function isContainLatinLetters(str){
    const latinRegex = /[\u0100-\u024F]/g;
    return latinRegex.test(str)
  }

  function checkLength(string){
    return String(string).length;
}

export default {fieldValidate , valdiatePhonenumber , validateEmail};