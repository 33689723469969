import Cookies from "js-cookie"
import ModalInterface from "./modalInterface"
import api from '../components/api/AxiosConfig'

const utils = {
    handleBackButton: async function(setPageContext , pageContext , hash){
        if(hash == "login" || hash == "welcome"){
            return
        }
        setPageContext({
            ...pageContext,
            showLogin: false,
            showWelcome: false,
            showStartGame: hash == "startgame" ? true : false,
            showMinigame: hash == "minigame" ? true : false ,
            showQuestions: hash == "questions" ? true : false,
            showUserInfo: hash == "userinfo" ? true : false,
            showCoinBank: hash == "coins" ? true : false,
            showScanner: hash == "scanner" ? true : false,
            showSpread: false,
            showCamera: false,
        })
    },
    handleLogout: async function handleLogout(setUserContext , setPageContext , setModalState , recaptchaState , setRecaptchaState , modalState ,pageContext , showProgress , setShowProgress, soundContext , setSoundContext){
        const initialModal = {
            title: '',
            body: '',
            img: '',
            visible: false,
            buttons: [],
            children: null,
            isCloseButton: true
          }

          const initialRecaptcha = {
            recaptchaLoginVisibility: false,
            recaptchaLoginStatus: false,
            recaptchaAnswerVisibility: false,
            recaptchaAnswerStatus: false
          }

          const initialUser = {
            firstName: '',
            lastName: '',
            userID: '',
            groupID: '',
            avatarPath: '',
            firstLogin: 0,
            coinBalance: 0,
          }

          const initialPage = {
            showLogin: true,
            showWelcome: false,
            showStartGame: false,
            showMinigame: false,
            showQuestions: false,
            showUserInfo: false,
            showCoinBank: false,
            showScanner: false,
            showSpread: false,
            showProgress: showProgress,
            setShowProgress: setShowProgress,
            showCamera: false,
            currentMinigame: 0,
            selectedDeck: 0,
            minigameStatus: -1, //0 == kids game 1 == adults
            selectedQuestion: null,
            modalState: modalState,
            loginCaptchaVisibility: false,
            loginCaptchaStatus: false,
            setModalState: setModalState,
            recaptchaState: recaptchaState,
            setRecaptchaState: setRecaptchaState,
            soundContext: soundContext,
            setSoundContext: setSoundContext,
            questionList: {},
            allowSounds: false,
            screenModal: false,
            screenUrl: null
          }
          
          setUserContext(initialUser)
          setPageContext(initialPage)
          pageContext.setModalState(initialModal)
          pageContext.setRecaptchaState(initialRecaptcha)
          Cookies.remove('access-token', {path: '/' , domain: `.${window.location.hostname}`})
        //   this.deleteCookie('access-token')
        //   try {
        //     const response = await api.post('/auth/logout')
        //   } catch (err) {
        //     console.log(err);
        //   }
          ModalInterface.closePopup(pageContext)
    },

    deleteCookie: function deleteCookie(cookieName) {
        document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },

    generateRandomString: function generateRandomString(type){
        let result = ''
        switch(type){
            case 1: 
                result = 'fullname_';
                break;
            case 2:
                result ='uuid_'
                break;
            case 3:
                result = 'email_'
                break;
            case 4:
                result = 'leader_'
                break;
            case 5:
                result = "key_"
                break;
        }
        
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        const resultLength = result.length

        for (let i = resultLength; i <  resultLength + 10; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        
        return result;
    },

    getHebrewMonth: function getHebrewMonth(index){
        switch(index){
            case 1:
                return "ינואר"
            case 2:
                return "פברואר"
            case 3:
                return "מרץ"
            case 4:
                return "אפריל"
            case 5:
                return "מאי"
            case 6:
                return "יוני"
            case 7:
                return "יולי"
            case 8:
                return "אוגוסט"
            case 9:
                return "ספטמבר"
            case 10:
                return "אוקטובר"
            case 11:
                return "נובמבר"
            case 12:
                return "דצמבר"
            default:
                return "Unvalid"
        }
    },

    convertSecondsToTime: function convertSecondsToTime(seconds){
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = seconds % 60;
        return `${h}:${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`;
    },

    getHebrewNumber: function getHebrewNumber(number){
        switch(number){
            case 1:
                return "ראשון"
                break
            case 2:
                return "שני"
                break
            case 3:
                return "שלישי"
        }
    },
    convertDate: function convertDate(dateString){
        const date = new Date(dateString);
        const month = date.getMonth() + 1; 
        const day = date.getDate();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        
        return `${day} ${this.getHebrewMonth(month)} ב - ${hours}:${minutes}`
    }
}

export default utils