import React, { useContext, useState, useEffect } from 'react'
import { PageContext, UserContext } from '../../../context/AppContext'
import api from '../../api/AxiosConfig'
import ProgressInterface from '../../../modules/progressInterface'
import './JuniorCardDeck.css'
import BGJ3 from '../../../images/collection_j_bg_1.svg'
import BGJ2 from '../../../images/collection_j_bg_2.svg'
import BGJ1 from '../../../images/collection_j_bg_3.svg'
import CollectionCard from '../collectioncard/CollectionCard'
import Svg from '../../../modules/svg'
import ModalInterface from '../../../modules/modalInterface'
import languages from '../../../language'

function JuniorCardDeck({ currentMinigame }) {
  const { pageContext, setPageContext } = useContext(PageContext)
  const { userContext } = useContext(UserContext)
  const backgroundObj = { 0: BGJ1, 1: BGJ2, 2: BGJ3 }
  const [details, setDetails] = useState({})
  const [allowClick, setAllowClick] = useState(false)

  async function getMinigameDetails() {
    
    try {
      ProgressInterface.showProgressBar(setPageContext, pageContext)
      const response = await api.post('/game/get-minigame-details', {
        minigame_id: currentMinigame
      }
      )
        .then((response) => {
          if (response.data.status == "success") {
            setDetails(response.data.details)
          } else {
            ModalInterface.createPopup(pageContext , "התראה" , languages.HE.errorMessages[response.data.message] , 'wrong-answer')
          }
        })
        .finally(() => {
          ProgressInterface.hideProgressBar(setPageContext, pageContext)
        })

    } catch (err) {
      console.log(err);
    }
    ProgressInterface.hideProgressBar(setPageContext, pageContext)
  }

  async function handleCollectionClick(index) {
    if (!allowClick) {
      return
    }
    setPageContext({
      ...pageContext,
      showMinigame: false,
      showQuestions: true,
      selectedDeck: index,
      showSpread: false,

    })
  }

  useEffect(() => {
    getMinigameDetails()
    setTimeout(() => {
      setAllowClick(true);
    }, 550);
  }, [currentMinigame])


  return (
    <div className='junior-card-deck'>
      {window.innerWidth > 480 ? <Svg fileName='logo_junior' /> : null}

      <div className='junior-card-deck-container' >
        {details ? Object.keys(details).map((index) => {
          return (
            <div key={index}>
              <CollectionCard onClick={() => handleCollectionClick(details[index].id)} title={details[index].title} bg={backgroundObj[index]} order={0} type={"junior"} id={details[index].id}></CollectionCard>
            </div>
          )
        }): null}
      </div>

    </div>
  )
}

export default JuniorCardDeck