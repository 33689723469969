import React, { useEffect , useState , useContext} from "react";
import api from '../api/AxiosConfig'
import { useZxing } from "react-zxing";
import { UserContext , PageContext } from "../../context/AppContext";
import ModalInterface from "../../modules/modalInterface";
import languages from "../../language";

function QRCodeReader(props) {
    const {userContext , setUserContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [allowScan , setAllowScan] = useState(true)
    const [result, setResult] = useState("");
    const { ref } = useZxing({
        onResult(result) {
            if(allowScan){
                setResult(result.getText());
                handleScan()
            }
        },
    });

    async function handleScan(){
        const url = result
        const qrCodeParam = url.match(/qr_code=(.*)/)[1].replace(/"/g , '')  
        try {
            const response = await api.post('/bank/qr_scan/use' , {
                user_id: userContext.userID,
                group_id: userContext.groupID,
                qr_code: qrCodeParam
            })
            if(response.data.status == "success"){
                setUserContext({
                    ...userContext,
                    coinBalance: response.data.userBalance
                })
                ModalInterface.createPopup(pageContext , "התראה" ,`היידה זכית ב${response.data.amount} מטבעות!` , 'right-answer')
                setAllowScan(false)
                setPageContext({
                    ...pageContext,
                    showScanner: false,
                    showCoinBank: true
                })
            } else {
                ModalInterface.createPopup(pageContext , "שגיאה" , languages.HE.errorMessages[response.data.message] , 'wrong-answer')
                setTimeout(() => setAllowScan(true),1000)
            }
        } catch(err) {
            console.log(err);
        }
    }
  return (
    <>
      <video style={{ height: '100vh', overflowY: 'hidden' }} ref={ref} />
    </>
  );
}

export default QRCodeReader