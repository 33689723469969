import React , {useContext , useState , useEffect} from 'react'
import './CoinCard.css'
import Svg from '../../../modules/svg'
import CustomButton from '../../custom-button/CustomButton'
import ModalInterface from '../../../modules/modalInterface'
import ProgressInterface from '../../../modules/progressInterface'
import api from '../../api/AxiosConfig'
import { UserContext , PageContext } from '../../../context/AppContext'
import languages from '../../../language'

function CoinCard({price , text , img , coins, onClick}) {
  const {userContext , setUserContext } = useContext(UserContext)
  const {pageContext , setPageContext } = useContext(PageContext)
  const [isLeader , setIsLeader] = useState(false)
 
  
  function handleClick(){
    if(!isLeader){
      ModalInterface.createPopup(pageContext, 'שגיאה' , 'רק קפטן יכול לקנות מטבעות לקבוצה!' , 'wrong-answer' , null ,null)
      return
    }
    ModalInterface.createPopup(pageContext, 'התראה' ,`האם אתה בטוח שאתה רוצה לקנות ${coins} מטבעות עבור ${price}?`,null,
     [{title: "כן" , onClick: () => handlePurchuse()} ,
      {title: 'לא' , onClick: () =>  ModalInterface.closePopup(pageContext)}] , null , false)
  }
  
 //Temp Function!
  async function handlePurchuse(){
    try {
      ProgressInterface.showProgressBar(setPageContext, pageContext)
      const response = await api.post('/bank/buy-coins' , {
        user_id: userContext.userID,
        group_id: userContext.groupID,
        price: price,
        amount: coins
      })
      if(response.data.status == 'success'){
        window.location.href = response.data.redirectUrl
      } else {
        ModalInterface.createPopup(pageContext , "שגיאה" , languages.HE.errorMessages[response.data.message] , "wrong-answer")
      }
      ModalInterface.closePopup(pageContext)
    } catch (err) {
      console.log(err);
    } 
  }

  function handleContext(balance){
    setUserContext({
      ...userContext,
      coinBalance: balance
    })
    
  }

  async function getTeamLeader(){
    try {
        const response = await api.post('/game/get-team-leader', {
            user_id: userContext.userID,
            group_id: userContext.groupID
        })
        if(response.data.status === "success"){
            if(userContext.userID == response.data.team_leader['team_leader']){
              setIsLeader(true)
            }
        }
    } catch (err){
        console.log(err);
    }
}

  useEffect(() => {
    getTeamLeader()
  }, [])

  return (
    <div className='coin-card-container'>
      <div className='coin-card-title'>
        <h2 >{price}</h2>
      </div>
      <div className='coin-card-text'>
        <p>{text}</p>
      </div>
        
        <div className='coin-card-img'>
            <Svg fileName={img} />
        </div>
        <div className='coin-card-button'>
            <CustomButton onClick={() => handleClick()} width={'calc(70% - 10px)'} height={'calc(30% -10px)'} fontSize={'16px'} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}} content={"קנה עוד מטבעות"}/>
        </div>
        
    </div>
  )
}

export default CoinCard