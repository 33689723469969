let options = {
    labels: {
        resetTitle: 'אפס כותרת',
        closeTitle: 'סגור כותרת',
        menuTitle: 'כותרת תפריט',
        increaseText: 'הגדל גודל טקסט',
        decreaseText: 'הקטן גודל טקסט',
        increaseTextSpacing: 'הגדל מרווח טקסט',
        decreaseTextSpacing: 'הקטן מרווח טקסט',
        increaseLineHeight: 'הגדל גודל שורה',
        decreaseLineHeight: 'הקטן גודל שורה',
        invertColors: 'ניגודיות הפוכה',
        grayHues: 'שחור לבן',
        bigCursor: 'סמן גדול',
        readingGuide: 'עוזר קריאה',
        disableAnimations: 'בטל אנימציות',
        underlineLinks: 'סמן קישורים'
    },
    modules: {
        increaseText: true,
        decreaseText: true,
        increaseTextSpacing: true,
        decreaseTextSpacing: true,
        increaseLineHeight: true,
        decreaseLineHeight: true,
        invertColors: true,
        grayHues: true,
        bigCursor: true,
        readingGuide: true,
        textToSpeech: false,
        speechToText: false,
        disableAnimations: true,
        underlineLinks: true,
    },
    session: {
        persistent: false,
    }
};

export { options };
