const ProgressInterface = {
    showProgressBar: function showProgressBar(setPageContext , pageContext){
      try{
        pageContext.setShowProgress(true)
      } catch (e){
        console.log(e);
      }
      
    },
    hideProgressBar: function hideProgressBar(setPageContext , pageContext){
      try{
        pageContext.setShowProgress(false)
      } catch (e) {
        console.log(e);
      }
      
    }
  }
  
  export default ProgressInterface
    
  
  
  