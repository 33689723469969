import React, { useContext, useEffect, useRef, useState } from 'react'
import './MinigameSelector.css'
import api from '../api/AxiosConfig'
import { UserContext, PageContext } from '../../context/AppContext';
import utils from '../../modules/utils';
import MinigameEmpty from '../../images/minigame-phase/mingame-empty.svg'
import MinigameCurrent from '../../images/minigame-phase/minigame-current.svg'
import MinigameComplete from '../../images/minigame-phase/minigame-complete.svg'
import Svg from '../../modules/svg';
import ModalInterface from '../../modules/modalInterface';
import languages from '../../language';

function MinigameSelector({minigame}) {
  const { userContext } = useContext(UserContext)
  const { pageContext, setPageContext } = useContext(PageContext)
  const [dates, setDates] = useState()
  const [time, setTime] = useState(getDate(3));
  const countDownRef = useRef()
  const [animatedClass , setAnimatedClass] = useState('')

  let styleSheet = {
    color: "rgba(255 , 255 , 255 , 0.4)"
  }

  function detectMinigame(index) {
    if (pageContext.currentMinigame == index) {
      return { img: "minigame-current", status: 1 }
    }
    if (pageContext.currentMinigame > index) {
      return { img: "minigame-complete", status: 2 }
    }
    return { img: "minigame-empty", status: 0 }
  }

  async function handleMinigameSelect(minigame) {
    if (pageContext.showSpread && pageContext.currentMinigame != 3) {
      ModalInterface.createPopup(pageContext , "התראה" , "עלייך לחזור אחורה על מנת להחליף שלב!" , "wrong-answer")
      return
    }
    if(pageContext.currentMinigame == minigame){
      return
    }
    try {
      const response = await api.post('/game/access-minigame', {
        "minigame_id": minigame,
        "group_id": userContext.groupID
      })
      if (!response.data) {
        return
      }
      if (response.data.status === "success") {
        setPageContext({
          ...pageContext,
          currentMinigame: minigame,
          selectedDeck: pageContext.currentMinigame == 3 ? 0 : pageContext.selectedDeck,
          showSpread: pageContext.currentMinigame == 3 ? false : pageContext.showSpread
        })
        setAnimatedClass('animate__headShake')
      } else {
        ModalInterface.createPopup(pageContext, 'שגיאה', languages.HE.errorMessages[response.data.message], 'wrong-answer')
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllDates() {
    try {
      const response = await api.get('/game/get-minigames-start-date')
      if (response.data.status === "success") {
        setDates(response.data.dates)
      } else {

      }
    } catch (error) {
      console.log(error);
    }
  }

  function getTimeDiff(timeObj) {
    const targetDate = new Date(timeObj.year, timeObj.month - 1, timeObj.day, timeObj.hour)
    const timeDiff = targetDate.getTime() - new Date().getTime()
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);
    const isWithin24Hours = hours < 24 && hours >= 0
    const timeDiffStr = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    if (!isWithin24Hours) {
      return { status: false }
    }
    return { status: true, time: timeDiffStr }

  }
  
  function getDate(index) {
    if (!dates) {
      return "24:00:00"
    }
    const obj = dates[index - 1].start_date
    let displayCountDown = getTimeDiff(obj)
    if (displayCountDown.status) {
      return displayCountDown.time
    }
    if (obj.id != index) {
      return "24:00:00"
    }
    let date = `${obj.day} ${utils.getHebrewMonth(obj.month)} ${obj.year}`
    return date
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getDate(3));
    }, 1000);

    return () => clearInterval(interval);
  }, [dates]);

  useEffect(() => {
    getAllDates()
  }, [])


  return (
    <div className='minigame-selector '>
      <div className={`minigame-selector-line`}>
        <Svg fileName={detectMinigame(1).img} onClick={() => handleMinigameSelect(1)} />
        <hr></hr>
        <Svg fileName={detectMinigame(2).img} onClick={() => handleMinigameSelect(2)} />
        <hr></hr>
        <Svg fileName={detectMinigame(3).img} onClick={() => handleMinigameSelect(3)} />
      </div>
      <div className='minigame-selector-text-container'>
        <div className='minigame-selector-text' style={detectMinigame(1).status == 0 ? styleSheet : null}>
          <div>שלב 1</div>
          <div>{getDate(1)}</div>
        </div>
        <div className='minigame-selector-text' style={detectMinigame(2).status == 0 ? styleSheet : null}>
          <div>שלב 2</div>
          <div>{getDate(2)}</div>
        </div>
        <div className='minigame-selector-text' style={detectMinigame(3).status == 0 ? styleSheet : null}>
          <div>שלב 3</div>
          <div>{getDate(3)}</div>
        </div>
      </div>
    </div>
  )
}

export default MinigameSelector