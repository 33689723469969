import React, { useState , useEffect , useContext} from 'react'
import api from '../api/AxiosConfig'
import './JuniorInfo.css'
import {UserContext , PageContext} from '../../context/AppContext';
import ModalInterface from '../../modules/modalInterface';
import languages from '../../language';
import CustomButton from '../custom-button/CustomButton';
import ProgressInterface from '../../modules/progressInterface';

function JuniorInfo(props) {
    const {userContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [buttonAdjustments, setButtonAdjustments] = useState({})
    const [deckInfo , setDeckInfo] = useState({})

    async function getDeckInfo(){
        try {
            ProgressInterface.showProgressBar(setPageContext, pageContext)
            const response = await api.post('/game/get-deck-info', {
                deck_id: pageContext.selectedDeck
            })
            if(response.data.status == "success"){
                setDeckInfo(response.data.info)
            } else {
                ModalInterface.createPopup(pageContext,'שגיאה' , languages.HE.errorMessages[response.data.message], 'wrong-answer')
            }
        } catch (err) {
            console.log(err);
        }
        ProgressInterface.hideProgressBar(setPageContext, pageContext)
    }

    function handleSize(){
        if(window.innerWidth <= 480){
          setButtonAdjustments({
            ...buttonAdjustments,
              width: "219px",
              height: "43px"
            
          })
        } else {
          setButtonAdjustments({
            ...buttonAdjustments,
              width: "300px",
              height: "59px"
            
          })
        }
      }

    useEffect(() => {
        getDeckInfo()
        handleSize()
    }, [pageContext.selectedDeck])
  return (
    <div className='junior-info-container'>
        <div className='junior-info-title'>
            <h1>{deckInfo.title}</h1>
        </div>
        <div className='junior-info-description'>
            <p>{deckInfo.description}</p>
            <p>מיקום התחלתי: {deckInfo.location}</p>
            <p>משך המסלול: {deckInfo.route_time}</p>
            <p>דרגת קושי: {deckInfo.route_difficulty}</p>
            <p>סוג מסלול: {deckInfo.route_type}</p>
            <p>חניה אפשרית: {deckInfo.parking_options}</p>
            <p>נגישות: {deckInfo.accessibility}</p>
            <p>ציוד: {deckInfo.equipment}</p>
            <CustomButton width={buttonAdjustments.width} height={buttonAdjustments.height} content={"התחל"} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}} fontColor={"#fff"} onClick={() => props.handleJuniorStart()} />
        </div>
        
    </div>
  )
}

export default JuniorInfo