import React , {useContext ,useEffect,useState} from 'react'
import './AddUser.css'
import CustomButton from '../../custom-button/CustomButton'
import {PageContext , UserContext} from '../../../context/AppContext';
import ModalInterface from '../../../modules/modalInterface'
import validateFields from '../../../modules/validateFields';
import api from '../../api/AxiosConfig'
import ProgressInterface from '../../../modules/progressInterface';
import languages from '../../../language';
import VerifyUser from '../verify-user/VerifyUser';

function AddUser({toggleForceUpdate}) {
    const {userContext , setUserContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [formData , setFormData] = useState({})
    const [message , setMessage] = useState()

    function handleTextChange(event){
        event.preventDefault()
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    async function handleOnClick(){
        let verify = validateFields.fieldValidate.validateNewUser(formData)
        if(!verify.status){
            setMessage(verify.message)
            return
        }
        try {
            ProgressInterface.showProgressBar(setPageContext , pageContext)
            const response = await api.post('auth/register-validate-ingame', {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email ? formData.email : '',
                phone: formData.phone ? formData.phone : '',
                group_id: userContext.groupID,
                user_id: userContext.userID,
                id_card: formData.id_card
            })
            if(response.data.status == "success"){
                ModalInterface.createPopup(pageContext , null ,null ,null ,null , <VerifyUser fromData={formData} toggleForceUpdate={toggleForceUpdate} /> , false)
            } else {
                setMessage(languages.HE.errorMessages[response.data.message])
            }
            ProgressInterface.hideProgressBar(setPageContext , pageContext)
            
        } catch(err) {
            console.log(err);
        }
        ProgressInterface.hideProgressBar(setPageContext , pageContext)
    }
    function buttonsControl(){
        ModalInterface.addButtons(pageContext , [{"title": "שלח" , "disabled": true, onClick: () => handleOnClick()}])
    }

    useEffect(() => {
        buttonsControl()
    },[formData])

  return (
    <div className='add-user-container'>
        <div className='add-user-header'>
        <p>הוסף שחקן</p>
        <div style={{color: "red" , display: "inline"}}>{message}</div>
        <div className='add-user-exit-button' onClick={() => ModalInterface.closePopup(pageContext)}> 
            X
        </div>
        </div>
        
        <form>
            <div className='add-user-top'>
                <input type='text' autoComplete='off' name='first_name' placeholder='שם פרטי' onChange={handleTextChange} required></input>
                <input type='text' autoComplete='off' name='last_name' placeholder='שם משפחה' onChange={handleTextChange} required></input>
            </div>
            <div className='add-user-middle'>
                <input placeholder='מספר טלפון' name='phone' minLength={10} maxLength={10} onChange={handleTextChange}></input>
                <input placeholder='דואר אלקטרוני' name='email' onChange={handleTextChange}></input>
            </div>
            <input style={{display: "none"}} name='id_card'/>
            
        </form>
            <div className='add-user-bottom'>
                {/* <CustomButton onClick={handleOnClick} content={"שלח"} width={"calc(40% - 10px)"} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}}></CustomButton> */}
            </div>
    </div>
  )
}

export default AddUser