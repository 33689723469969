import React, { useState } from 'react'
import './CustomButton.css'
import Svg from '../../modules/svg'

export default function CustomButton({ width, height, content, fontSize, fontColor, backgroundColor, backgroundGradiant, onClick, boxShadow, border, zIndex, oneTime, img, imgContent, block, margin, marginFold, widthFold, heightFold }) {
  const [disabled, setDisabled] = useState(false)
  let styleSheet = {}
  let containerStyleSheet = {}
  if (window.innerWidth == 280) {
    margin = marginFold;
    width = widthFold;
    height = heightFold;
  }
  if (width || height || fontSize || fontColor || (backgroundColor && !backgroundGradiant) || margin || boxShadow || border) {
    styleSheet = {
      width: width,
      height: height,
      color: fontColor,
      fontSize: fontSize,
      backgroundColor: backgroundColor,
      boxShadow: boxShadow,
      border: border,
      margin: margin,
    }
  }

  if (zIndex) {
    containerStyleSheet = {
      zIndex: zIndex
    }
  }


  if (backgroundGradiant) {
    styleSheet = {
      ...styleSheet,
      background: `linear-gradient(to right, ${backgroundGradiant.top}, ${backgroundGradiant.bottom})`
    }
  }

  function onButtonClick(event) {
    event.preventDefault();
    if (block) {
      return
    }
    if (oneTime) {
      setDisabled(true)
    } else {
      setDisabled(true)
      setTimeout(() => setDisabled(false), 1500)
    }
    if (onClick) {
      onClick()
    }

  }



  return (
    <div className='custom-button-container' style={containerStyleSheet}>
      <button disabled={disabled} onClick={(e) => onButtonClick(e)} style={styleSheet} className='custom-button'>
        <div className='custom-button-content'>
          {img ?
            <div className='custom-button-coin'>
              <Svg fileName={img} />
              <span className='custom-button-coin-price'>{imgContent}</span>
            </div>
            : null}


          {content}
        </div>


      </button>
    </div>
  )
}
