import React  from 'react'

const UserContext = React.createContext()

const PageContext = React.createContext()

const AnimContext = React.createContext()

const SettingsContext = React.createContext()

export  {UserContext , PageContext , AnimContext, SettingsContext};