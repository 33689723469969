import React, { useEffect, useState , useContext } from 'react'
import './ExitGroupModal.css'
import {PageContext} from '../../../context/AppContext';
import CustomButton from '../../custom-button/CustomButton';
import ModalInterface from '../../../modules/modalInterface';

function ExitGroupModal({exitGroup}) {
    const [code , setCode] = useState(0)
    const [userCode , setUserCode] = useState()
    const [message , setMessage] = useState()
    const {pageContext , setPageContext } = useContext(PageContext)
    
    // bind function to an variable to rerender it on every modal call so the code will be the last genereated code.
    const checkCode = () => {
        if(userCode == code){
            exitGroup()
        } else {
            setMessage('הקוד שהזנת שגוי!')
        }
    }

    function generateCode(){
        const uCode = Math.floor(Math.random() * 900000) + 100000;
        console.log("in", uCode)
        setCode(uCode)
    }

    function handleChange(event){
        setUserCode(event.target.value)
    }


    function buttonsControl(){
        ModalInterface.addButtons(pageContext , [{"title": "אימות" , "disabled": "true" , onClick: () => checkCode()}])
    }

    useEffect(() => {
        generateCode()
    },[])

    useEffect(() => {
        buttonsControl()
    },[userCode])
  return (
    <div className='exit-group-modal-container' >
        <div className='exit-group-modal-exit-button' onClick={() => ModalInterface.closePopup(pageContext)}>
            X
        </div>
        <div className='exit-group-modal-top'>
            <h4>נא להזין את הקוד בתיבת הטקסט</h4>
            <h3>{code}</h3>
        </div>
        <div>
            <input className='exit-group-modal-input' maxLength={6} onChange={handleChange}></input>
        </div>
        <div>
            {message ? <div className='exit-group-modal-error'>{message}</div> : null}
        </div>
        <div className='exit-group-button-container'>
            {/* <CustomButton onClick={() => checkCode()} content={'אימות'} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}}/> */}
        </div>
    </div>
  )
}

export default ExitGroupModal