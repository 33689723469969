import soundHelper from "../audio/SoundHelper"

const ModalInterface = {
  createPopup: function createPopup(pageContext, title, body, img, buttons, children, isCloseButton = true, style, allowSound = true) {
    pageContext.setModalState({
      ...pageContext.modalState,
      title: title,
      body: body,
      img: img,
      visible: true,
      buttons: buttons,
      children: children,
      isCloseButton: isCloseButton,
      style: style
    })

    if(img == "wrong-answer"){
      if(allowSound){
        soundHelper.playSound(pageContext.setSoundContext, false , false ,true)
      }
    } else {
      if(allowSound){
        soundHelper.playSound(pageContext.setSoundContext, false , false ,false ,false ,true, false)
      }
      
    }

  },
  closePopup: function closePopup(pageContext) {
    pageContext.setModalState({
      visible: false,
    })
  },
  addButtons: function addButtons(pageContext,buttons){
    pageContext.setModalState(prev => ({
      ...prev,
      buttons: buttons
    }))
  },
  removeButtons: function removeButtons(pageContext){
    pageContext.setModalState(prev => ({
      ...prev,
      buttons: null
    }))
  }
}

export default ModalInterface



