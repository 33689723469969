import React, { useState, useContext, useRef, useEffect } from 'react'
import api from '../../api/AxiosConfig'
import ModalInterface from '../../../modules/modalInterface';
import ReCAPTCHA from 'react-google-recaptcha';
import languages from '../../../language'
import CustomButton from '../../custom-button/CustomButton';
import utils from '../../../modules/utils';
import './Auth.css'
import { UserContext, PageContext } from '../../../context/AppContext';
import ProgressInterface from '../../../modules/progressInterface';
import settings from '../../../settings';

export default function Auth(props) {
    const { userContext, setUserContext } = useContext(UserContext)
    const { pageContext, setPageContext } = useContext(PageContext)
    const [captcha, setCaptcha] = useState({
        visible: false,
        isRobot: true,
    });
    const captchaRef = useRef();
    const [formData, setFormData] = useState({})
    const [color, setColor] = useState("#000000")
    const [countdown, setCountdown] = useState(120);
    const [allowResend, setAllowResend] = useState(false)
    const [resendText, setResendText] = useState("לא קיבלתי קוד")
    const intervalRef = useRef()
    const [message, setMessage] = useState("הזינו את הקוד שקיבלתם ב-SMS");
    const emailString = utils.generateRandomString(3)
    const [buttonAdjustments, setButtonAdjustments] = useState({ width: "150px" })

    function detectLoginInput() {
        if (isValidEmail(props.phonenumber)) {
            setMessage("הזינו את הקוד שקיבלתם בדואר אלקטורני")
        } else {
            setMessage("הזינו את הקוד שקיבלתם ב-SMS")
        }
    }

    function isValidEmail(email) {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }

    async function handleAuthentication(event) {
        if (captcha.visible) {
            if (captcha.isRobot) {
                setMessage(languages.HE.errorMessages["no_captcha_filled"])
                return
            }
        }
        try {
            const response = await api.post('/auth/validate', {
                "login_info": props.phonenumber,
                "authCode": formData.authCode,
                "email": formData.email,
                "recaptcha_token": captcha.visible ? captchaRef?.current.getValue() : null,
                "version": settings.version
            }, {withCredentials: true}
            )
            if (response.data['status'] === "success") {
                let currentMinigame = response.data['currentMinigame']
                handleContext(response.data['userInfo'], response.data['coin_balance'])
                if (response.data['userInfo'][0].first_login === 0) {
                    setPageContext({
                        ...pageContext,
                        showLogin: false,
                        showWelcome: true,
                        currentMinigame: 1
                    })

                } else {
                    setPageContext({
                        ...pageContext,
                        showLogin: false,
                        showStartGame: true,
                        currentMinigame: currentMinigame
                    })
                }
                ModalInterface.closePopup(pageContext)
            } else {
                await updateLoginAttempt()
                await getLoginAttempts()
                setMessage(languages.HE.errorMessages["invalid_auth_code"])
                setColor("#FF0000")
            }
        }
        catch (error) {
            console.log(error);
        }
        handleRecaptchaExpire()
    }

    async function updateLoginAttempt() {
        try {
            const response = await api.post('/auth/update-login-attempts', {
                "login_info": props.phonenumber,
            })
        }
        catch (error) {
            console.log(error);
        }
    }

    function handleSize() {
        if (window.innerWidth <= 480) {
            setButtonAdjustments({
                width: "60%",
            })
        }
    }

    function handleChange(event) {
        if (event.target.name == "authCode") {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value
            })
        } else {
            setFormData({
                ...formData,
                email: event.target.value
            })
        }

    }

    function handleContext(userData, balance) {
        setUserContext({
            ...userContext,
            firstName: userData[0].first_name,
            lastName: userData[0].last_name,
            userID: userData[0].id,
            groupID: userData[0].group_id,
            avatarPath: userData[0].avatarPath,
            firstLogin: userData[0].first_login,
            coinBalance: balance
        })
    }

    function onCaptchaChange() {
        setCaptcha({
            ...captcha,
            isRobot: false
        })
    }

    function handleRecaptchaExpire() {
        if (captchaRef) {
            captchaRef?.current.reset()
        }
        setCaptcha({
            ...captcha,
            isRobot: true
        })
    }

    async function getLoginAttempts() {
        try {
            const response = await api.post('/auth/get-login-attempts', {
                "login_info": props.phonenumber,
            })
            let isRobot = false
            let visible = false
            if (response.data.status === "required") {
                if (!captcha.visible) {
                    visible = true
                    isRobot = true
                }
            } else if (response.data.status === "blocked") {
                visible = true
                isRobot = true
                setMessage(languages.HE.errorMessages["auth_blocked"])
                setColor("#FF0000")
            } else if (response.data.status === "blocked_pending") {
                let time = response.data.time
                if (time < 0) {
                    detectLoginInput()
                    setColor("#000000")
                } else {
                    setMessage("נחסמת! נסה שוב בעוד " + Math.round(response.data.time) + " דקות")
                    setColor("#FF0000")
                }
            } else {
                isRobot = false
                visible = false
            }
            setCaptcha({
                isRobot: isRobot,
                visible: visible
            })
        }
        catch (error) {
            console.log(error);
        }
    }


    async function handleReSend() {
        if (!allowResend) {
            return
        }
        try {
            const response = await api.post('/auth/resend-auth-code', {
                login_info: props.phonenumber
            })
            if (response.data.status == "success") {
                setResendText("לא קיבלתי קוד")
                setAllowResend(false)
                setCountdown(120)
            } else {
                setMessage(`${languages.HE.errorMessages[response.data.message][0]}
                  ${Math.round(response.data.time)}
                  ${languages.HE.errorMessages[response.data.message][1]}`)
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getLoginAttempts()
        handleSize()
        detectLoginInput()
    }, [])

    useEffect(() => {
    }, [captcha.isRobot, captcha.visible])


    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);
        return () => clearInterval(intervalRef.current);
    }, [countdown]);

    useEffect(() => {
        if (countdown === 0) {
            setResendText("שלח שוב")
            setAllowResend(true)
            clearInterval(intervalRef.current);
        }
    }, [countdown]);


    return (

        <div className='auth-container'>
            <div className='auth-close-button' onClick={() => ModalInterface.closePopup(pageContext)}>X</div>
            <p style={{ color: color }}>{message}</p>
            <form className='auth-form'>
                <input className='auth-form-input' name={'authCode'} placeholder={"קוד אימות"} onChange={handleChange} required></input>
                <input type={'text'} name={emailString} style={{ display: "none" }} onChange={handleChange}></input>
                {captcha.visible ? <ReCAPTCHA sitekey='6LdgP74lAAAAAIdzsywGvk98BPF1OuZ-kT42rnhy' onExpired={handleRecaptchaExpire} onChange={onCaptchaChange} ref={captchaRef} id="recaptchaLogIn" className='auth-recaptcha-container' /> : null}
                <CustomButton width={buttonAdjustments.width} widthFold="200px" heightFold="35px" marginFold="-3% 0px 0px 0px" content={"שליחה"} onClick={handleAuthentication} backgroundGradiant={{ top: "rgba(21, 138, 110, 1)", bottom: "rgba(3, 62, 81, 1)" }} oneTime={false} ></CustomButton>
            </form>
            <div className='auth-countdown' style={{ opacity: allowResend ? "1" : "0.7", cursor: allowResend ? 'pointer' : 'default' }} onClick={() => handleReSend()}>
                <h5>{resendText}</h5>
                <div>{countdown !== 0 ? countdown : null}</div>
            </div>
        </div>

    )
}
