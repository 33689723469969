import React, {useContext, useEffect} from 'react'
import './PageMinigame.css'

import BGA from '../../images/minigame-bg/bg-brown.svg'
import api from '../../components/api/AxiosConfig'
import {UserContext , PageContext} from '../../context/AppContext';
import CollectionCard from '../../components/card/collectioncard/CollectionCard'
import MinigameSelector from '../../components/minigame-selector/MinigameSelector'
import Svg from '../../modules/svg'
import CardDeck from '../../components/card-deck/CardDeck'
import JuniorCardDeck from '../../components/card/junior-card-deck/JuniorCardDeck'
import PageQuestions from '../questions/PageQuestions'
import CardSpread from '../../components/card-speard/CardSpread'
import ProgressInterface from '../../modules/progressInterface';
import ModalInterface from '../../modules/modalInterface';
import languages from '../../language';

export default function PageMinigame(props) {
  const {userContext  } = useContext(UserContext)
  const {pageContext , setPageContext } = useContext(PageContext)

  function speardThirdMinigame(){
    if(pageContext.currentMinigame == 3){
      getMinigameDetails()
    }
  }

  async function getMinigameDetails(){
    try {
      const response = await api.post('/game/get-minigame-details', {
        minigame_id: pageContext.currentMinigame
      })
      .then((response) => {
        if(response.data.status == "success"){
          setPageContext({
            ...pageContext,
            showSpread: true,
            selectedDeck: response.data.details[0].id,
        })
        } else {
          ModalInterface.createPopup(pageContext , "התראה" , languages.HE.errorMessages[response.data.message] , 'wrong-answer')
        }
      })
    } catch (err) { 
      console.log(err);
    }
  }
  useEffect(()=>{
    window.location.hash = 'minigame'
    speardThirdMinigame()
  },[pageContext.currentMinigame, pageContext.selectedQuestion ,pageContext.showSpread])

  
  return (
    
      <div className='minigame-container'>
        {pageContext.minigameStatus === 1
          ?
          <div className='minigame-sub-container'>
            <div className='minigame-header'>
              {window.innerWidth >= 480 ? <Svg fileName='adult_logo_small'></Svg> : null }
              
            </div>
            <MinigameSelector />
              <div className='minigame-collection'>
                {pageContext.selectedDeck == 0 ? 
                <CardDeck currentMinigame={pageContext.currentMinigame} /> :
                <CardSpread />
                }
              </div>
          </div> 
          
          :
          <div>
            <div className='minigame-header'>
            
            </div>
              <div className='minigame-collection'>
                <JuniorCardDeck currentMinigame={pageContext.currentMinigame} />
              </div>
          </div>
          }
      </div>
    
  )
}
