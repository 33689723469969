import io from 'socket.io-client';
import settings from "../settings";
import { SettingsContext } from '../context/AppContext';
import React, { useContext, useEffect } from 'react';

const host = `${window.location.hostname}`;
const url = window.location.href.includes("localhost") ? `${window.location.protocol}//${host}:3200` : `${window.location.protocol}//server.${host}`;
console.log("socket url:", url);
let socket;

const SocketComponent = () => {
  const { settingsContext, setSettingsContext } = useContext(SettingsContext);

  useEffect(() => {
    try {
      socket = io(url, {
        transports: ['websocket']
      });

      socket.on("settingsUpdate", (response) => {
        console.log(response);
        if(response){
          setSettingsContext(response);
        } else {
          console.log("unable to retrieve updated settings");
        }
      });
    } catch (e) {
      console.warn("Unable to connect to socket. ", e);
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  return null; // This component doesn't render anything
};

export { socket, SocketComponent };
