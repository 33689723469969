import React, { useEffect, useState, useContext, useRef } from 'react'
import './JuniorQuestionCard.css'
import api from '../../api/AxiosConfig'
import { UserContext, PageContext, AnimContext , SettingsContext} from '../../../context/AppContext'
import CustomButton from '../../custom-button/CustomButton'
import ModalInterface from '../../../modules/modalInterface'
import fieldValidate from '../../../modules/validateFields'
import languages from '../../../language'
import { ReCAPTCHA } from 'react-google-recaptcha';
import utils from '../../../modules/utils'
import ProgressInterface from '../../../modules/progressInterface'
import HintModal from '../../modal/hintmodal/HintModal'
import animateCSS from '../../../modules/animate'
import soundHelper from '../../../audio/SoundHelper'

export default function JuniorQuestionCard(props) {
  const { userContext, setUserContext } = useContext(UserContext)
  const { pageContext, setPageContext } = useContext(PageContext)
  const { animContext, setAnimContext } = useContext(AnimContext)
  const { settingsContext, setSettingsContext } = useContext(SettingsContext)

  const captchaRef = useRef(null);
  const [answer, setAnswer] = useState()
  const [hint, setHint] = useState()
  const [isLeader, setIsLeader] = useState(false)
  const [showHint, setShowHint] = useState(false)
  const [remainingHints, setRemainingHints] = useState(0)
  const [buttonAdjustments, setButtonAdjustments] = useState({ short: {}, long: {} })
  const [question, setQuestion] = useState()
  const [questionID, setQuestionID] = useState()
  const [forceUpdate, setForceUpdate] = useState()

  const [leader, setLeader] = useState()
  const leaderString = utils.generateRandomString(4)

  const intervalRef = useRef()
  const [isCooldown, setIsCooldown] = useState(false)
  const [timer, setTimer] = useState(0)
  const [startAnim, setStartAnim] = useState(false)

  async function verifyAnswer() {
    let verify = await fieldValidate.fieldValidate.validateAnswer(answer, settingsContext)
    if (!verify.status) {
      ModalInterface.createPopup(pageContext, "שגיאה", "נא לבדוק את פורמט התשובה!", "wrong-answer")
    }
    return verify.status
  }

  async function sendAnswer(event) {
    if (!isLeader) {
      ModalInterface.createPopup(pageContext, 'שגיאה', 'רק קפטן של קבוצה יכול לענות על חידות!', 'wrong-answer')
      return
    }
    if (!(await verifyAnswer())) {
      return
    }
    try {
      const response = await api.post('/game/verify-answer-junior', {
        "answer": answer,
        "question_id": questionID,
        "user_id": userContext.userID,
        "group_id": userContext.groupID,
        "deck_id": pageContext.selectedDeck,
        "minigame_id": pageContext.currentMinigame,
        "group_leader": leader,
      })
      if (response.data.status === "success") {

        if (response.data.deck_status == "done") {
          console.log("playing rain");
          soundHelper.playSound(pageContext.setSoundContext, false , false , false , false , false, true)
          setAnimContext(prev => ({
            ...prev,
            showCoinRain: true
          }))
          hanldeContext(response.data.balance)
          ModalInterface.createPopup(pageContext, "אי אי קפטן", " כל הכבוד סיימת את השלב! זכית ב10 מטבעות!", "right-answer" , null , null , true , null , false)
          setPageContext({
            ...pageContext,
            showQuestions: false,
            showMinigame: true,
          })
        } else {
          ModalInterface.createPopup(pageContext, "ענית נכון", "תשובה נכונה, וממשיכים לחידה הבאה", "right-answer")
          setShowHint(false)
          setHint(null)
          soundHelper.playSound(pageContext.setSoundContext, false , true)
          setAnimContext(prev => ({
            ...prev,
            showConfetti: true,
          }))
          setTimeout(() => {
            setAnimContext(prev => ({
              ...prev,
              showConfetti: false
            }))
          }, 5000)
          setForceUpdate(prev => (!prev))
        }
      } else if (response.data.status == 'failure') {
        getGroupAnswerAttempts()
        ModalInterface.createPopup(pageContext, "תשובה שגויה", "התשובה שכתבת אינה נכונה נסה שוב או המשך", "wrong-answer")
      } else if (response.data.status == 'required') {
        ModalInterface.createPopup(pageContext, 'התראה', `עלייך להמתין ${timer} שניות לפני שליחה חוזרת!`, 'wrong-answer')
      }
    } catch (error) {
      console.log(error);
    }
  }

  function hanldeContext(balance) {
    setUserContext({
      ...userContext,
      coinBalance: balance
    })
  }

  async function hanldeHintClick() {
    if (!isLeader) {
      ModalInterface.createPopup(pageContext, "שגיאה", languages.HE.errorMessages['not a team leader'], "wrong-answer")
      return
    }
    if (!hint) {
      if (!await getHintStatus()) {
        if (remainingHints != 0) {
          // pop up of how many hints left
          // ModalInterface.createPopup(pageContext,
          //   'התראה',
          //   `נשארו לך ${remainingHints} רמזים , האם אתה בטוח שאתה רוצה לקבל רמז?`,
          //   null ,
          //   [{title:'כן' , onClick: () => getHint()} ,
          //   {title: 'לא' , onClick: () => ModalInterface.closePopup(pageContext)}],
          //   null ,
          //   false)
          getHint()
        } else {
          // ModalInterface.createPopup(pageContext, 'התראה', `נגמרו לך הרמזים!`, 'wrong-answer')
        }
      } else {
        getHint()
      }
    } else {
      handleShowHint()
    }
  }

  function handleShowHint() {
    if (showHint) {
      setStartAnim(true)
      setTimeout(() => setShowHint(false), 500)

    } else {
      setStartAnim(false)
      setShowHint(true)
    }
  }

  async function getGroupLeader() {
    try {
      const response = await api.post('/game/get-team-leader', {
        user_id: userContext.userID,
        group_id: userContext.groupID
      })
      if (response.data.status == "success") {
        if (userContext.userID == response.data.team_leader.team_leader) {
          setIsLeader(true)
        }
      }

    } catch (err) {
      console.log(err);
    }
  }

  function handleSize() {
    if (window.innerWidth <= 480) {
      setButtonAdjustments({
        ...buttonAdjustments,
        short: {
          width: "104px",
          height: "43px"
        },
        long: {
          width: "219px",
          height: "43px"
        }
      })
    } else {
      setButtonAdjustments({
        ...buttonAdjustments,
        short: {
          width: "143px",
          height: "59px"
        },
        long: {
          width: "300px",
          height: "59px"
        }
      })
    }
  }

  async function getRemainingHints() {
    try {
      const response = await api.post('/game/get-remaining-hints', {
        user_id: userContext.userID,
        group_id: userContext.groupID
      })
      if (response.data.status == "success") {
        setRemainingHints(response.data.remainingHints)
      }
    } catch (err) {

    }
  }

  async function getHintStatus() {
    try {
      const response = await api.post('/game/get-hint-status', {
        question_id: questionID,
        group_id: userContext.groupID,
        user_id: userContext.userID,
        minigame_id: pageContext.currentMinigame
      })
      if (response.data.status == "success") {
        return true
      } else {
        return false
      }
    } catch (err) {

    }
  }

  async function getHint() {
    try {
      if (!hint) {
        const response = await api.post('/game/get-hint', {
          question_id: questionID,
          group_id: userContext.groupID,
          user_id: userContext.userID,
          minigame_id: pageContext.currentMinigame
        })
        if (response.data.status === "success") {
          setHint(response.data.hint[0].hint)
          handleShowHint()
          ModalInterface.closePopup(pageContext)
        } else {
          let body = languages.HE.errorMessages[response.data.message]
          ModalInterface.createPopup(pageContext, "שגיאה", body, "wrong-answer")
        }

      } else {
        // let body = languages.HE.errorMessages[response.data.message]
        handleShowHint()
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange(event) {
    if (event.target.name === "answer") {
      setAnswer(event.target.value)
    } else {
      setLeader(event.targer.value)
    }

  }


  async function getJuniorQuestions() {
    try {
      ProgressInterface.showProgressBar(setPageContext, pageContext)
      const response = await api.post('/game/get-question-junior', {
        deck_id: pageContext.selectedDeck,
        group_id: userContext.groupID
      })
      if (response.data.status == "success") {
        setAnswer('')
        if (response.data.questionList[0]) {
          setQuestion(response.data.questionList[0].question)
          setQuestionID(response.data.questionList[0].id)
          setPageContext(prev => ({
            ...prev,
            selectedQuestion: response.data.questionList[0].id
          }))
        }
      }
      ProgressInterface.hideProgressBar(setPageContext, pageContext)

      getGroupAnswerAttempts(response.data.questionList[0].id)
    } catch (err) {
      console.log(err);
    }
    ProgressInterface.hideProgressBar(setPageContext, pageContext)
  }

  function handleSelectedQuestion(id) {
    setPageContext({
      ...pageContext,
      selectedQuestion: id
    })
  }

  async function getGroupAnswerAttempts(question_id) {
    try {
      const response = await api.post('/game/get-group-answer-attempts', {
        user_id: userContext.userID,
        group_id: userContext.groupID,
        question_id: questionID ? questionID : question_id,
        minigame_id: pageContext.currentMinigame
      })
      if (response.data.status == 'required') {
        setIsCooldown(true)
        setTimer(Math.ceil(response.data.time))
      } else {
        setIsCooldown(false)
        setTimer(0)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [timer]);

  useEffect(() => {
    if (timer === 0) {
      setIsCooldown(false)
      clearInterval(intervalRef.current);
    }
  }, [timer]);


  useEffect(() => {
    handleSize()
    getRemainingHints()
    getJuniorQuestions()
    getGroupLeader()
  }, [forceUpdate])

  return (
    <div className='question-card-content'>
      {isCooldown ? <div className='question-overlay'>
        <p>ניסית לענות על השאלה יותר מידי פעמיים</p>
        <p>השאלה חסומה ל-{timer} שניות</p>
      </div> : null}
      <div className='question-card-question' dangerouslySetInnerHTML={{ __html: question }}>

      </div>
      <form>
        {isLeader ? <input name='answer' type={"text"} onChange={handleChange} placeholder={"התשובה שלך"} value={answer} required></input> : null}
        <input autoComplete='off' name={leaderString} type={"text"} onChange={handleChange} style={{ display: 'none' }}></input>
        <ReCAPTCHA sitekey='6LeD2FQlAAAAADHjryuCIYz49gCt275xvTFbqF1q' badge='bottomright' size='invisible' ref={captchaRef} />
      </form>
      {showHint ?
        <div className={`question-card-hint-placeholder animate__animated ${showHint ? 'animate__fadeIn' : ''} ${startAnim ? 'animate__fadeOut' : ''}`}>
          <HintModal handleShowHint={handleShowHint} show={showHint} hint={hint} />
        </div>
        : null}
      <div className='question-card-button-container'>
        <div className='question-card-button-sub-container-split-buttons'>
          <CustomButton onClick={hanldeHintClick} widthFold='220px' width={buttonAdjustments.long.width} height={buttonAdjustments.short.height} content={"רמז"} backgroundGradiant={{ top: "rgba(193, 187, 31, 1)", bottom: "rgba(91, 113, 4, 1)" }}></CustomButton>
        </div>
        <div>
          <CustomButton width={buttonAdjustments.long.width} height={buttonAdjustments.long.height} onClick={sendAnswer} content={"המשך"} backgroundGradiant={{ top: "rgba(21, 138, 110, 1)", bottom: "rgba(3, 62, 81, 1)" }}></CustomButton>
        </div>

      </div>

    </div>
  )
}
