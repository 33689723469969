import axios from "axios";
import ModalInterface from "../../modules/modalInterface";

const host = `${window.location.hostname}`
let url = ''

if(host.includes("localhost")){
    url = `${window.location.protocol}//${host}:3200`
} else {
    url = `${window.location.protocol}//server.${host}`
}


//url = 'http://192.168.1.106:3200'

const axiosInstance = axios.create({
    baseURL: url,
    headers: { "ngrok-skip-browser-warning": "true" },
    withCredentials: true,
  });
  
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error here
        // For example, you can redirect to a login page or show an error message
      }
      return Promise.reject(error);
    }
  );
  
  export default axiosInstance;