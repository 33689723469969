import React , {useEffect, useRef , useContext} from 'react'
import './SideMenu.css'
import Svg from '../../modules/svg'
import settings from '../../settings';
import {SettingsContext } from '../../context/AppContext'

function SideMenu({show, toggle , userCoins, moveToUserInfo , moveToCoinBank , handleLogout, setScreenModal}) {
  const sideMenuRef = useRef(null)
  const { settingsContext, setSettingsContext } = useContext(SettingsContext)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sideMenuRef.current.classList.contains('open') && !sideMenuRef.current.contains(event.target)) {
          toggle()
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

      

  return (
    <div className={`sidemenu ${show ? 'open' : ''}`} ref={sideMenuRef} >
      {show ? <div className='version-control-bot'>version {settingsContext.version}</div> : null} 
        <div className='side-menu-container'>
            <div className='side-menu-close-button'>
                <Svg fileName='x' onClick={toggle}/>
            </div>
            <div className='side-menu-logo'>
                <Svg fileName='logo_main' />
            </div>
            <div className='side-menu-game-buttons-container'>
                <div className='side-menu-game-button' onClick={() => moveToUserInfo()}>
                    <Svg fileName='user-icon' />
                    <span>הקבוצה</span>
                </div>
                <div className='side-menu-game-button' onClick={() => moveToCoinBank()}>
                    <div className='coins'>
                        <Svg fileName='coin-holder' />
                        <span className='coin-balance'>{userCoins}</span>
                    </div>
                    <span>מטבעות</span>
                </div>
             </div>
                <hr className='side-menu-divider'></hr>
                <div className='side-menu-links'>
                <a onClick={() =>setScreenModal("rules")} target='_blank'>חוקי המשחק</a>
                <a onClick={() =>setScreenModal("rewards")} target='_blank'>פרסים</a>
                <a onClick={() =>setScreenModal("contact-us")} target='_blank'>צור קשר</a>
                <a onClick={() =>setScreenModal("did-you-know")} target='_blank'>הידעת?</a>
                  <p onClick={() => handleLogout()}>התנתק</p>
                </div>
                
            
        </div>
        
    </div>
  )
}

export default SideMenu