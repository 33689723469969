import React , {useContext , useRef ,useState , useEffect} from 'react'
import './ChangeCredentialsVerify.css'
import api from '../../api/AxiosConfig'
import { UserContext , PageContext } from '../../../context/AppContext'
import ModalInterface from '../../../modules/modalInterface'
import CustomButton from '../../custom-button/CustomButton'
import languages from '../../../language'

function ChangeCredentialsVerify({email , phonenumber , sendSMS , sendEmail}) {
    const {userContext , setUserContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [message ,setMessage] = useState()
    const [authCode , setAuthCode] = useState()
    const intervalRef = useRef()
    const [countdown , setCountdown] = useState(120);
    const [allowResend , setAllowResend] = useState(false)
    const [resendText , setResendText] = useState("לא קיבלתי קוד")

    async function verifyAuthCode(){
        try {  
            const response = await api.post('/auth/verify-change-credentails', {
                user_id: userContext.userID,
                phonenumber: phonenumber,
                email: email,
                code: authCode
            })
            if(response.data.status == "success"){
                ModalInterface.createPopup(pageContext , "התראה" , "פרטי ההתחברות שלך שונו בהצלחה!" , 'right-answer')
            } else {
                setMessage(languages.HE.errorMessages[response.data.message])
            }
        } catch(err){
            console.log(err);
        }
    }

    function hanldeChange(event){
        setAuthCode(event.target.value)
    }

    async function resendAuthCode(){
        try {
            const response = await api.post('/auth/resend-change-credentials-authcode' ,{
                user_id: userContext.userID,
                isSendEmail: email && !phonenumber ? true : false,
                phone: phonenumber,
                email: email
            })
            if(response.data.status == "success"){
                setCountdown(120)
                setAllowResend(false)
                setResendText("לא קיבלתי קוד")
            } else {
                setMessage(languages.HE.errorMessages[response.data.message])
            }
        } catch (err) {
            console.log(err);
        }
    }

    function addButtons(){
        ModalInterface.addButtons(pageContext , [{"title": "שלח" , "disabled": true , onClick: () => verifyAuthCode()}])
    }

    useEffect(() => {
        intervalRef.current = setInterval(() => {
          setCountdown(countdown - 1);
        }, 1000);
        return () => clearInterval(intervalRef.current);
      }, [countdown]);
    
      useEffect(() => {
        if (countdown === 0) {
          setResendText("שלח שוב")
          setAllowResend(true)
          clearInterval(intervalRef.current);
        }
      }, [countdown]);

      useEffect(() => {
        addButtons()
      },[authCode])
  return (
    <div className='change-credentails-verify-container'>
        <div className='change-credentials-verify-exit-button' onClick={() => ModalInterface.closePopup(pageContext)}>
            X
        </div>
       <p className='change-credentails-verify-header'>אימות החלפת נתונים</p>
       <p>הכנס קוד אימות שנשלח אלייך {phonenumber && !email || sendSMS ? "בטלפון" : "במייל"}</p>
       {message ?<p style={{color: 'red'}}>{message}</p>:null}
       
       <form className='change-credentials-verify-form'>
        <input placeholder='קוד אימות' onChange={hanldeChange} maxLength={6} ></input>
       </form>
       <div className='change-credentials-verify-countdown' style={{opacity: allowResend ? "1" : "0.7" , cursor: allowResend ? 'pointer' : 'default'}} onClick={() => resendAuthCode()}>
            <h5>{resendText}</h5>
            <div>{countdown !== 0 ? countdown : null}</div>
        </div> 
       {/* <CustomButton onClick={() => verifyAuthCode()} width={window.innerWidth > 480 ? "calc(40% - 10px)" : "calc(50% - 10px)"} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}} content={"שלח"} /> */}
    </div>
  )
}

export default ChangeCredentialsVerify