import React, { useState , useContext, useEffect } from 'react'
import './VerifyUser.css'
import {PageContext , UserContext} from '../../../context/AppContext';
import api from '../../api/AxiosConfig'
import CustomButton from '../../custom-button/CustomButton'
import languages from '../../../language';
import ModalInterface from '../../../modules/modalInterface';

function VerifyUser({fromData , toggleForceUpdate}) {
    const {userContext , setUserContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [message , setMessage] = useState("")
    const [code , setCode] = useState()
    
    function handleSubmit(event){
        event.preventDefault()
        handleClick()
    }

    function handleTextChange(event){
        setCode(event.target.value)
    }

    async function handleClick(){
        try {
            const response = await api.post('/auth/register-ingame-auth-validation', {
                first_name: fromData.first_name,
                last_name: fromData.last_name,
                email: fromData.email ? fromData.email : '',
                phone: fromData.phone ? fromData.phone : '',
                group_id: userContext.groupID,
                user_id: userContext.userID,
                code: code
            })
            if(response.data.status == "redirect"){
                window.location.href = response.data.redirectUrl
                ModalInterface.closePopup(pageContext)
            } else if (response.data.status == "success"){
                toggleForceUpdate()
                ModalInterface.closePopup(pageContext)
            } else {
                setMessage(languages.HE.errorMessages[response.data.message])
            }
        } catch (err) {
            console.log(err);
        }
    }

    function buttonsControl(){
        ModalInterface.addButtons(pageContext , [{"title": "אימות" , "disabled": "true" , onClick: () => handleClick()}])
    }

    useEffect(() => {
        buttonsControl()
    },[code])
  return (
    <div className='verify-user-container'>
        <div className='verify-user-exit-button' onClick={() => ModalInterface.closePopup(pageContext)}>X</div>
        <div className='verify-user-top'>
            <p>הכנס קוד שקיבלת ב SMS</p>
            <div style={{color: 'red'}}>{message}</div>
        </div>
        <div className='verify-user-middle'>
            <form onSubmit={handleSubmit}>
                <input placeholder='קוד אימות' onChange={handleTextChange} required maxLength={6}></input>
                {/* <CustomButton onClick={handleClick} content={"שלח"} width={"calc(100% - 10px)"} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}}/> */}

            </form>
        </div>
        <div className='verify-user-bottom'>
        </div>
    </div>
  )
}

export default VerifyUser