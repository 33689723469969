import React , {useContext , useState , useEffect } from 'react'
import { UserContext , PageContext } from '../../../context/AppContext'
import api from '../../api/AxiosConfig'
import './ChangeCredentialsModal.css'
import ModalInterface from '../../../modules/modalInterface'
import CustomButton from '../../custom-button/CustomButton'
import languages from '../../../language'
import ProgressInterface from '../../../modules/progressInterface'
import validateFields from '../../../modules/validateFields'
import ChangeCredentialsVerify from '../change-credentials-verify/ChangeCredentialsVerify'

function ChangeCredentialsModal() {
    const {userContext , setUserContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const [userCredentails , setUserCredentails] = useState({})
    const [message , setMessage] = useState("")
    const [phonenumber , setPhonenumber] = useState()
    const [email ,setEmail] = useState()
    const [id_card ,setIdCard] = useState()
    const [verify , setVerify] = useState(false)
    const [sendEmail , setSendEmail] = useState(false)
    const [sendSMS , setSendSMS] = useState(false)

    async function getUserCredentials(){
        try {
            ProgressInterface.showProgressBar(setPageContext, pageContext)
            const response = await api.post('/auth/get-user-credentials', {
                user_id: userContext.userID
            })
            if(response.data.status == "success"){
                setUserCredentails(response.data.userCredentails[0])
            } else {
                ModalInterface.createPopup(pageContext , "שגיאה" , languages.HE.errorMessages[response.data.message] , "wrong-answer")
            }
            ProgressInterface.hideProgressBar(setPageContext, pageContext)
        } catch (err) {
            console.log(err);
        }
        ProgressInterface.hideProgressBar(setPageContext, pageContext)
    }

    function handleChange(event){
        
        if(event.target.name == "phone"){
            setPhonenumber(event.target.value)
        } else if (event.target.name == "email") {
            setEmail(event.target.value)
        } else {
            setIdCard(event.target.value)
        }
    }

    function clearForm(){
        setEmail(null)
        setPhonenumber(null)
        setVerify(false)
        setMessage(null)
    }

    async function changeCredentialHandler(){
        console.log("here");
        console.log(phonenumber);
        console.log(email);
        if(!phonenumber && !email){
            setMessage("עלייך למלא לפחות אחד מהפרטים")
            return
        }
        let validate = validateFields.fieldValidate.validateUserCredentials(phonenumber , email)
        if(!validate.status){
            setVerify(false)
            setMessage(validate.message)
            return
        }

        try {
            const response = await api.post('/auth/check-if-user-exists', {
                phonenumber: phonenumber,
                email: email
            })
            console.log(response);
            if(response.data.status == "failure"){
                setMessage(languages.HE.errorMessages[response.data.message])
                return
            } else {
                setVerify(true)
                setMessage("")
            }
        } catch(err){
            console.log(err);
        }
    }

    function moveToVerify(){
        handleSendAuthCode()
    }

    function detectPlatform(){
        if(phonenumber && !email){
            return false
        }
        if(!phonenumber && email){
            return true
        }
        if(phonenumber && email){
            return sendEmail
        }
    }

    async function handleSendAuthCode(){
        try {
            const response = await api.post('/auth/change-credentails-send-auth-code' , {
                user_id: userContext.userID,
                email: email,
                phonenumber: phonenumber,
                sendEmail: detectPlatform()
            })
            if(response.data.status == "success"){
                setMessage("")
                ModalInterface.createPopup(pageContext , null , null , null , null, <ChangeCredentialsVerify phonenumber={phonenumber} email={email} sendSMS={sendSMS} sendEmail={sendEmail} /> , null)
            } else {
                let body = languages.HE.errorMessages[response.data.message][0] + Math.round(response.data.time) + " שניות לפני ניסיון חוזר"
                setMessage(body)
            }
        } catch(err) {
            console.log(err);
        }
    }

    function toggleCheckBox(type){
        if(type === "email"){
            setSendSMS(false)
            setSendEmail(true)
        } else {
            setSendEmail(false)
            setSendSMS(true)
        }
    }

    function buttonControl(){
        if(!verify){
            ModalInterface.addButtons(pageContext, [{"title": "שלח", "disabled": true , onClick: () => changeCredentialHandler()}])
        } else {
            ModalInterface.addButtons(pageContext, [{"title": "כן אני בטוח" ,"disabled": true , onClick: () => moveToVerify()} , {"title": "לא, אני רוצה לשנות","disabled": true , onClick: () => clearForm()}])
        }
    }

    useEffect(() => {
        getUserCredentials()
      
    },[])

    useEffect(() =>{
        buttonControl()
    },[verify, email, phonenumber])


  return (
    <div className='change-credentials-modal-container'>
        {!verify ? 
        <div style={{width: "100%"}}>
        <div className='change-credentials-modal-exit-button' onClick={() => ModalInterface.closePopup(pageContext)}>
            X
        </div>
        <p className='change-credentials-modal-header'>החלפת פרטים</p>
        
        <form className='change-credentials-modal-form'>
            <label>מספר טלפון</label>
            <input placeholder={userCredentails.phone} name='phone' maxLength={10} onChange={handleChange}></input>
            <label>דואר אלקטרוני</label>
            <input placeholder={userCredentails.email} name='email' maxLength={50} onChange={handleChange}></input>
            <input name='id_card' style={{display: 'none'}} onChange={handleChange}></input>
            
        </form>
        <div style={{color: 'red'}}>{message}</div>
        <div>
        {/* <CustomButton onClick={() => changeCredentialHandler()} width={window.innerWidth > 480 ? "calc(40% - 10px)" : "calc(50% - 10px)"} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}} content={"שלח"} /> */}

        </div>
    </div>
     
    : <div>
         <div className='change-credentials-modal-exit-button' onClick={() => ModalInterface.closePopup(pageContext)}>
            X
        </div>
        <p className='change-credentials-modal-header'>בדיקת נתונים</p>
        <div className='change-credentials-text'>
            <p>האם אתה בטוח שאתה רוצה להחליף את הפרטים הבאים?</p>
            {phonenumber ? <p>מספר טלפון: {phonenumber} </p> : null}
            {email ? <p> דואר אלקטרוני: {email}</p> : null}
        </div>
        <p style={{color: 'red'}}>{message}</p>
        {email && phonenumber ? 
        <div className='change-credentials-modal-radio'>
            <div>
                <input type='checkbox' checked={sendEmail} onChange={() => toggleCheckBox("email")}></input>
                <label>שלח קוד אימות למייל</label>
                
            </div>
            <div>
                <input type='checkbox' checked={sendSMS} onChange={() => toggleCheckBox("sms")}></input>
                <label>שלח קוד אימות לטלפון</label>
            </div>
            
        </div> : null}
        <div className='change-credentials-buttons'>
            {/* <CustomButton onClick={() => moveToVerify()} width={window.innerWidth > 480 ? "calc(40% - 10px)" : "calc(50% - 10px)"} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}} content={"כן אני בטוח"} />
            <CustomButton onClick={() => clearForm()} width={window.innerWidth > 480 ? "calc(40% - 10px)" : "calc(50% - 10px)"} backgroundGradiant={{top: "rgba(21, 138, 110, 1)" , bottom: "rgba(3, 62, 81, 1)"}} content={"לא, אני רוצה לשנות"} /> */}
        </div>
  </div>}  
    </div>
  )
}

export default ChangeCredentialsModal