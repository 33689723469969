import React from 'react'
import './ProgressCircle.css'

function ProgressCircle() {


  return (
     <div className="circle-container">
      <div className="circle-progress"></div>
    </div>
  )
}

export default ProgressCircle