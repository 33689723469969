import React, { useEffect, useRef } from 'react'
import './PdfModal.css'
import Svg from '../../../modules/svg';
import TOS1 from '../../../images/pdf/termofservice/termofservice_page-0001.jpg'
import TOS2 from '../../../images/pdf/termofservice/termofservice_page-0002.jpg'
import TOS3 from '../../../images/pdf/termofservice/termofservice_page-0003.jpg'
import TOS4 from '../../../images/pdf/termofservice/termofservice_page-0004.jpg'
import TOS5 from '../../../images/pdf/termofservice/termofservice_page-0005.jpg'
import TOS6 from '../../../images/pdf/termofservice/termofservice_page-0006.jpg'
import TOS7 from '../../../images/pdf/termofservice/termofservice_page-0007.jpg'
import TOS8 from '../../../images/pdf/termofservice/termofservice_page-0008.jpg'
import PP1 from '../../../images/pdf/privacypolicy/privacypolicy_page-0001.jpg'
import PP2 from '../../../images/pdf/privacypolicy/privacypolicy_page-0002.jpg'
import PP3 from '../../../images/pdf/privacypolicy/privacypolicy_page-0003.jpg'



function PdfModal({pdfName , visibility , handleVisibility}) {
    const tosArray = [TOS1 , TOS2 , TOS3 , TOS4, TOS5,TOS6,TOS7,TOS8]
    const ppArray = [PP1,PP2,PP3]
    const pdfRef = useRef(null)

    useEffect(() => {
        const handleOutsideClick = (event) => {
          if (!pdfRef.current.contains(event.target)) {
            handleVisibility()
          }
        };
        document.addEventListener('mousedown', handleOutsideClick);
    
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, []);
  return (
    <div className='pdf-modal' ref={pdfRef} style={{display: visibility ? 'block' : 'none'}}>
        <div className='pdf-modal-exit-button'><Svg fileName={'x'} onClick={handleVisibility}/></div>
        <div className='pdf-modal-content'>
            {
            pdfName == 'termofservice' ? 
            <div>
                {tosArray.map((img , index) => {
                    return <img key={index} src={img} />
                })}
            </div>
            : 
            <div>
                {ppArray.map((img , index) => {
                    return <img key={index} src={img} />
                })}
            </div>
            }
        </div>
    </div>
  )
}

export default PdfModal