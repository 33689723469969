import React from 'react';
import AdultLogoSmall from '../images/adult_logo_small.svg'
import JuniorLogoSmall from '../images/junior_main_logo_small.svg'
import AdultMap from '../images/adult_map.svg'
import AnswerCard from '../images/answer_card.svg'
import BackArrow from '../images/back-arrow.svg'
import Background from '../images/background.svg'
import Bg from '../images/bg.svg'
import CameraPlaceHolder from '../images/camera_place_holder.svg'
import CameraSmallIcon from '../images/camera_small_icon.svg'
import CardMobile from '../images/card_mobile.svg'
import CardDivider from '../images/card-divider.svg'
import CoinBankCard from '../images/coin-bank-card.svg'
import CoinHolder from '../images/coin-holder.svg'
import CollectionJuniorBG1 from '../images/collection_j_bg_1.svg'
import CollectionJuniorBG2 from '../images/collection_j_bg_2.svg'
import CollectionJuniorBG3 from '../images/collection_j_bg_3.svg'
import DropArrow from '../images/droparrow.svg'
import HamburgerMenu from '../images/hamburger_menu.svg'
import JuniorMap from '../images/junior_map.svg'
import LogoHeader from '../images/logo_header.svg'
import LogoHeader1 from '../images/logo_header1.svg'
import LogoJunior from '../images/logo_junior.svg'
import LogoMain from '../images/logo_main.svg'
import LogoMain1 from '../images/logo_main1.svg'
import MobileHeaderLogo from '../images/mobile_header_logo.svg'
import Popup from '../images/pop-up.svg'
import QuestionDone from '../images/question_done.svg'
import ShipIcon from '../images/ship.svg'
import SmallIcon from '../images/small-coin.svg'
import StartGameAdultLogo from '../images/startgame_adult_logo.svg'
import StartGameBorder from '../images/startgame_border.svg'
import StartGameJuniorLogoPhone from '../images/startgame_junior_logo_phone.svg'
import StartGameJuniorLogo from '../images/startgame_junior_logo.svg'
import UserIcon from '../images/user-icon.svg'
import X from '../images/x.svg'
import Settings from '../images/settings.svg'
import PhotoPirate from '../images/pirate_fountain.svg'


import CoinLarge from '../images/coin-bank-options/coin-large.svg'
import CoinMedium from '../images/coin-bank-options/coin-medium.svg'
import CoinSmall from '../images/coin-bank-options/coin-small.svg'
import CoinXSmall from '../images/coin-bank-options/coin-xsmall.svg'

import MinigameBG from '../images/minigame-bg/bg-brown.svg'
import MinigameEmpty from '../images/minigame-phase/mingame-empty.svg'
import MinigameCurrent from '../images/minigame-phase/minigame-current.svg'
import MinigameComplete from '../images/minigame-phase/minigame-complete.svg'

import PopupRightAnswer from '../images/popup-imgs/right-answer.svg'
import PopupWrongAnswer from '../images/popup-imgs/wrong-answer.svg'
import PopupFinish from '../images/popup-imgs/finish-route.svg'
import PopupAlert from '../images/popup-imgs/alert.svg'

const svgObj = {
  "adult_logo_small": AdultLogoSmall,
  "junior_logo_small": JuniorLogoSmall,
  "adult_map": AdultMap,
  "answer_card": AnswerCard,
  "back-arrow": BackArrow,
  "background": Background,
  "bg": Bg,
  "camera_place_holder": CameraPlaceHolder,
  "camera_small_icon": CameraSmallIcon,
  "card_mobile": CardMobile,
  "card-divider": CardDivider,
  "coin-bank-card": CoinBankCard,
  "coin-holder": CoinHolder,
  "collection_j_bg_1": CollectionJuniorBG1,
  "collection_j_bg_2": CollectionJuniorBG2,
  "collection_j_bg_3": CollectionJuniorBG3,
  "droparrow": DropArrow,
  "hamburger_menu": HamburgerMenu,
  "junior_map": JuniorMap,
  "logo_header": LogoHeader,
  "logo_header1": LogoHeader1,
  "logo_junior": LogoJunior,
  "logo_main": LogoMain,
  "logo_main1": LogoMain1,
  "mobile_header_logo": MobileHeaderLogo,
  "pop-up": Popup,
  "question_done": QuestionDone,
  "ship": ShipIcon,
  "small-coin": SmallIcon,
  "startgame_adult_logo": StartGameAdultLogo,
  "startgame_border": StartGameBorder,
  "startgame_junior_logo_phone": StartGameJuniorLogoPhone,
  "startgame_junior_logo": StartGameJuniorLogo,
  "user-icon": UserIcon,
  "x": X,
  "coin-large": CoinLarge,
  "coin-medium": CoinMedium,
  "coin-small": CoinSmall,
  "coin-xsmall": CoinXSmall,
  "minigame-bg": MinigameBG,
  "minigame-empty": MinigameEmpty,
  "minigame-current": MinigameCurrent,
  "minigame-complete": MinigameComplete,
  "right-answer": PopupRightAnswer,
  "wrong-answer": PopupWrongAnswer,
  "finish-route": PopupFinish,
  "alert": PopupAlert,
  "settings": Settings,
  "photo-info": PhotoPirate,
};

const svgFiles = require.context('../images', true, /\.svg$/);

const Svg = ({ fileName  , onClick , alt}) => {
  // const SvgIcon = svgFiles(`./${fileName}.svg`);
  return <img src={svgObj[fileName]} alt={alt} onClick={onClick} />;
};

export default Svg; 
