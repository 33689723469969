import React , {useEffect, useRef, useState} from 'react'
import './CustomSpinner.css'

function CustomSpinner({setShowSpinner , replaceLeaderHandler , isLeader , leaveGroupHandler , playerKickHandler , changeCredentialsHandler , key , leaderChange}) {
  const spinnerRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!spinnerRef.current.contains(event.target)) {
        setShowSpinner(key)
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
    return (
      <div ref={spinnerRef} className='custom-spinner animate__animated animate__fadeInLeft'>
        <div className='custom-spinner-close-button' onClick={() => setShowSpinner(key)}>X</div>
        {leaderChange ? 
        <div className='custom-spinner-element'>
          <button onClick={() => changeCredentialsHandler()}>ערוך פרטים</button>
          
        </div> 
        : 
        <div>
          {isLeader ? 
          <div className='custom-spinner-element'>
            <button onClick={() => replaceLeaderHandler()}>החלף קפטן</button>
            <span></span>
            <button onClick={() => playerKickHandler()} >הסר שחקן</button>
        </div>
          :
          <div className='custom-spinner-element'>
            <button onClick={() => changeCredentialsHandler()}>ערוך פרטים</button>
            <span></span>
            <button onClick={() => leaveGroupHandler()}>צא מהקבוצה</button>
        </div> }
        </div>}
        
        
        
      </div>
    );
}

export default CustomSpinner