import React from 'react'
import './CoinBankHistory.css'

function CoinBankHistory({time , action , amount , price}) {
  function formatTime(originalTime){
    const [time, date] = originalTime.split(' ');

  // Split the date into year, month, and day
    const [year, month, day] = date.split('-');

// Rearrange the date parts and add leading zeros if necessary
    const convertedDate = `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year.slice(2)}`;

// Combine the converted date and original time
      const convertedTime = `${convertedDate}  ${time}`;
    return convertedTime
  }
  return (
    <tr className='coin-bank-history-container'>
        <td>{formatTime(time)}</td>
        <td>{action}</td>
        <td>{amount}</td>
        <td>{price}</td>
    </tr>
  )
}

export default CoinBankHistory