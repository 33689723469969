import React, { useState , useEffect , useContext} from 'react'
import './PageQuestions.css'
import Card from '../../components/card/Card'
import MinigameCard from '../../components/card/minigamecard/MinigameCard'
import CameraInfoCard from '../../components/card/camera-info-card/CameraInfoCard'
import CameraCard from '../../components/card/cameracard/CameraCard'
import cardBG from '../../images/answer_card.svg'
import cardBGM from '../../images/card_mobile.svg'
import api from '../../components/api/AxiosConfig'
import ReCAPTCHA from 'react-google-recaptcha'
import {UserContext , PageContext , AnimContext} from '../../context/AppContext';
import QuestionCard from '../../components/card/questioncard/QuestionCard'
import JuniorQuestionCard from '../../components/card/junior-question-card/JuniorQuestionCard'
import BGA from '../../images/minigame-bg/bg-brown.svg'
import settings from '../../settings'
import ModalInterface from '../../modules/modalInterface'
import JuniorInfo from '../../components/junior-info/JuniorInfo'
import languages from '../../language'
import utils from '../../modules/utils'
import soundHelper from '../../audio/SoundHelper'
import ProgressInterface from '../../modules/progressInterface'

export default function PageQuestions(props) {
  
    const {userContext } = useContext(UserContext)
    const {pageContext , setPageContext } = useContext(PageContext)
    const {animContext , setAnimContext} = useContext(AnimContext)

    const [isQuestionSubmit , setQuestionSubmit] = useState('')
    const [isCameraInfo , setIsCameraInfo] = useState(false)
    const [isInstuctions , setIsInstuctions] = useState(false)
    const [cardAdjustments , setCardAdjustments] = useState({})
    const [answer , setAnswer] = useState()
    const [disalbeUpload , setDisableUpload] = useState(false)
    const [fireworks , setFireworks] = useState(false)
    const [cardAnimation , setCardAnimation] = useState('animate__zoomIn')

    useEffect(() => {
        handleSize()
    }, [props.show , isQuestionSubmit])

    function handleCards(answer){
      setAnswer(answer)
      setQuestionSubmit(true)
    }

    function handleCardsJunior(){
      
      setPageContext({
        ...pageContext,
        showQuestions: false ,
        showMinigame: true
      })
    }

    function handleSize(){
      if(window.innerWidth <= 480){
        setCardAdjustments({
          ...cardAdjustments,
          width: "356px",
          height: "633px",
          background: cardBGM
        })
      } else {
        setCardAdjustments({
          ...cardAdjustments,
          width: "554px",
          height: "758px",
          background: cardBG
        })
      }
      
    }

    function handleCameraInfo(){
      if(isCameraInfo){
        setIsCameraInfo(false)
      } else {
        setIsCameraInfo(true)
      }
      
    }

    function handleJuniorStart(){
      setIsInstuctions(true)
    }

    async function handleSubmit(selectedFile){
      try {
        if(!selectedFile){
          ModalInterface.createPopup(pageContext, "שגיאה"  , "לא בחרת תמונה!" , "wrong-answer")
          return
        }
        ProgressInterface.showProgressBar(setPageContext, pageContext)
        setDisableUpload(true)
        const formData = new FormData()
        formData.append('user_id' , userContext.userID)
        formData.append('group_id', userContext.groupID)
        formData.append('question_id', pageContext.selectedQuestion)
        formData.append('answer' , answer)
        formData.append('image' , selectedFile)
        formData.append('minigame_id' , pageContext.currentMinigame)

        const response = await api.post('/game/upload', formData)
        if(response.data.status == "success"){
          ProgressInterface.hideProgressBar(setPageContext, pageContext)
           setPageContext(prev => ({
            ...prev,
            showMinigame: true,
            showQuestions: false,
            showCamera: false,
            showSpread: true,
            selectedQuestion: 0
          }))
          soundHelper.playSound(pageContext.setSoundContext, false , false , false ,true)
          setAnimContext(prev => ({
            ...prev,
            showConfetti: true
          }))
          setTimeout(() => {
            setAnimContext(prev => ({
              ...prev,
              showConfetti: false
            }))
          }, 5000)
          setQuestionSubmit(false)
          setIsCameraInfo(false)
          if(response.data.minigame == "done"){
            let body
            if(response.data.game_done){
              body = `אלופים! סיימתם את שלב 3 בהצלחה! מערכת המרוץ למטמון תיצור איתכם קשר, אם אתם אלו שסיימו ראשונים את השלב השלישי.`
            } else {
              if(response.data.next_minigame){
                body = `כל הכבוד! סיימתם את השלב ה${utils.getHebrewNumber(pageContext.currentMinigame)}. בהצלחה בשלב ה${utils.getHebrewNumber(pageContext.currentMinigame + 1)}!`
              } else {
                body = `כל הכבוד סיימתם את השלב ה${utils.getHebrewNumber(pageContext.currentMinigame)} , השלב ה${utils.getHebrewNumber(pageContext.currentMinigame + 1)} יפתח ב - ${utils.convertDate(response.data.next_minigame_time)}`
              }
            }
            ModalInterface.createPopup(pageContext , "עברת את השלב" , body , "finish-route" , null , null , true , null ,false)
          } else {
              ModalInterface.createPopup(pageContext , "תשובה נכונה" , "ממשיכים לחידה הבאה!", "right-answer" , null , null , true , null , false)
          }
        } else {
          ProgressInterface.hideProgressBar(setPageContext, pageContext)
          ModalInterface.createPopup(pageContext, "שגיאה"  , languages.HE.errorMessages[response.data.message] , "wrong-answer")
        }
        ProgressInterface.hideProgressBar(setPageContext, pageContext)
        setDisableUpload(false)
      } catch (err) {
        console.log(err);
      }
      ProgressInterface.hideProgressBar(setPageContext, pageContext)
    }

    async function isDemoGameDone(){
      const response = await api.post('/game/is-demo-deck-done', {
        user_id: userContext.userID,
        group_id: userContext.groupID
      })
      if(response.data.status != "success"){
        return false
      }
      if(response.data.is_deck_done){
        return true
      }
      return false
    }

    function wrongAnswerAnimation(){
      setCardAnimation('animate__shakeX')
      setTimeout(() => setCardAnimation('') ,1000)
    }

    useEffect(() => {
      window.location.hash = 'questions'
      setTimeout(() => setCardAnimation('') ,1000)
    },[])

     
  return (
    <main>
      {pageContext.minigameStatus == 1 ? 
      
        <div className='questions-select'>
        {isQuestionSubmit ?
        <>
        {!isCameraInfo ? 
        <Card boxShadow={"none"} width={cardAdjustments.width} height={cardAdjustments.height} backgroundImage={cardAdjustments.background} position={"relative"}>
          <CameraInfoCard handleCameraInfo={handleCameraInfo} />
        </Card> :
        <Card boxShadow={"none"} width={cardAdjustments.width} height={cardAdjustments.height} backgroundImage={cardAdjustments.background} position={"relative"}>
          <CameraCard disalbeUpload={disalbeUpload} handleCameraInfo={handleCameraInfo} question_id={pageContext.selectedQuestion} handleSubmit={handleSubmit} />
        </Card>
        }
          
        </>
          
        : 
          <Card boxShadow={"none"} width={cardAdjustments.width} height={cardAdjustments.height} backgroundImage={cardAdjustments.background}  position={"relative"} animation={cardAnimation}>
            <QuestionCard wrongAnswerAnim={wrongAnswerAnimation} question_id={pageContext.selectedQuestion} handleCards={handleCards} setFireworks={setFireworks} />
          </Card> 
        } 
      </div>
      : <div className='questions-select'>
        {!isInstuctions ? 
        <JuniorInfo handleJuniorStart={handleJuniorStart} />
        :
        <Card boxShadow={"none"} width={cardAdjustments.width} height={cardAdjustments.height} backgroundImage={cardAdjustments.background}  position={"relative"} animation={'animate__zoomIn'}>
            <JuniorQuestionCard handleCards={handleCardsJunior} />
        </Card> 
        }
          
      </div> 
      } 
      
    </main>
  )
}
