import React, { useEffect, useRef, useState } from 'react'
import './Card.css'
import animateCSS from '../../modules/animate'
import RedCard from '../../images/card-bg/card-red.svg'
import GreenCard from '../../images/card-bg/card-green.svg'
import BrownCard from '../../images/card-bg/card-brown.svg'
import BlueCard from '../../images/card-bg/card-blue.svg'
import PurpleCard from '../../images/card-bg/card-purple.svg'
import BlackCard from '../../images/card-bg/card-black.svg'
import WhiteCard from '../../images/card-bg/card-white.svg'
import GrayCard from '../../images/card-bg/card-gray.svg'

export default function Card({ children, width, height, animationDelay, order, minigame, position, display, onClickEvent, done, backgroundColor, backgroundImage, backgroundSize, radius, boxShadow, top, left, animation , block}) {
  let styleSheet = {}
  const [unclickable, setunClickable] = useState("card-container-unclickable")
  if (width || height || order || position || backgroundColor || backgroundImage || radius || boxShadow || top || left) {
    styleSheet = {
      position: position,
      top: top,
      left: left,
      width: width,
      height: height,
      animationDelay: animationDelay,
      zIndex: order,
      backgroundColor: backgroundColor,
      backgroundImage: `url(${getColor()})`,
      borderRadius: radius,
      boxShadow: boxShadow,
      backgroundSize: backgroundSize,
    }
  }

  function getColor(){
    switch(backgroundImage){
      case "red":
        return RedCard
      case "brown":
        return BrownCard
      case "green":
        return GreenCard
      case "blue":
        return BlueCard
      case "purple":
        return PurpleCard
      case "black":
        return BlackCard
      case "white":
        return WhiteCard
      case "gray":
        return GrayCard
      default: 
      return backgroundImage
    }
  }

  onClickEvent = done ? () => { } : onClickEvent

  useEffect(() => {
    setTimeout(() => setunClickable(""), 1000)
  }, [])
  
  useEffect(() => {
    console.log("Done?");
  }, [done])


  return (
    <div style={styleSheet} className={`card-container ${unclickable} animate__animated ${animation}`} onClick={block ? null :onClickEvent} >
      {children}
    </div>
  )
}

