import React, { useEffect, useRef } from 'react'
import './ScreenModal.css'
import Svg from '../../../modules/svg';

function ScreenModal({setPC, url}) {
    const screenModalRef = useRef(null)
    function closeModal(){
        setPC(prev =>({
            ...prev,
            screenModal: false,
            screenUrl: null
        }))
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
          if (!screenModalRef.current.contains(event.target)) {
              closeModal()
          }
        };
    
        document.addEventListener('mousedown', handleOutsideClick);
    
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, []);

    useEffect(() => {
        
    },[])
  return (
    <div className='screen-modal'>
        <div className='screen-modal-exit-button'>
            <Svg fileName={'x'} onClick={() => closeModal()}/>
        </div>
        <iframe ref={screenModalRef} src={url} width="100%" height="100%"></iframe>
    </div>
  )
}

export default ScreenModal