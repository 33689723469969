import React, { useEffect, useState, useContext } from 'react'
import './CollectionCard.css'
import Card from '../Card'
import api from '../../api/AxiosConfig'
import MinigameCard from '../minigamecard/MinigameCard'
import { UserContext, PageContext } from '../../../context/AppContext'
import ProgressInterface from '../../../modules/progressInterface'

function CollectionCard({ pos, bg, order, onClick, title, type, id , color}) {
    const { userContext } = useContext(UserContext)
    const { pageContext, setPageContext } = useContext(PageContext)
    const innerWidth = window.innerWidth
    const innerHeight = window.innerHeight

    const [cardWidth, setCardWidth] = useState("206px")
    const [cardHeigth, setCardHeigth] = useState("360px")
    const [cardPosition, setCardPosition] = useState({})
    const [juniorStatus, setJuniorStatus] = useState(false)

    async function getJuniorStatus() {
        if (type != 'junior') {
            return
        }
        try {
            const response = await api.post('/game/get-junior-decks-status', {
                group_id: userContext.groupID,
                deck_id: id
            })
            if (response.data.deck_status == "done") {
                setJuniorStatus(true)
            }
        } catch (err) {
            console.log(err);
        }
        ProgressInterface.hideProgressBar(setPageContext, pageContext)
    }

    function handleSize() {
        if (innerWidth <= 480) {
            setCardWidth("149px")
            setCardHeigth("260px")
            setCardPosition({
                ...cardPosition,
                top1: "20px",
                top2: "10px",
                top3: "0px",
                left1: `${order * 10}px`,
                left2: `${order * 10 + 10}px`,
                left3: `${order * 10 + 20}px`,
            })
        } else {
            setCardPosition({
                ...cardPosition,
                top1: "20px",
                top2: "10px",
                top3: "0px",
                left1: `${order * 10}px`,
                left2: `${(order * 10) + 10}px`,
                left3: `${(order * 10) + 20}px`
            })
        }
    }

    useEffect(() => {
        handleSize()
        getJuniorStatus()
    }, [])
    //animate__animated animate__backInDown animate__delay-${order}s
    return (
        <div className={`collection-card `} style={juniorStatus ? { opacity: 0.7 } : null}>
            <Card onClickEvent={onClick} animation={'animate__fadeInUp '} animationDelay={'500ms'} position={"absolute"} done={juniorStatus} top={cardPosition.top1} left={cardPosition.left1} width={cardWidth} height={cardHeigth} order={3} boxShadow={"none"} radius={"4px"} backgroundImage={color ? color : bg} type={"junior"}>
                <MinigameCard done={juniorStatus} type={"junior"} location={title} ></MinigameCard>
            </Card>

            <Card block={true} onClickEvent={onClick} animation={'animate__fadeInUp'} animationDelay={'250ms'} position={"absolute"} top={cardPosition.top2} left={cardPosition.left2} width={cardWidth} height={cardHeigth} order={2} boxShadow={"none"} radius={"4px"} backgroundImage={color ? color : bg} type={"junior"}>
                <MinigameCard done={juniorStatus} type={"junior"} ></MinigameCard>
            </Card>

            <Card block={true} onClickEvent={onClick} animation={'animate__fadeInUp '} position={"absolute"} top={cardPosition.top3} left={cardPosition.left3} width={cardWidth} height={cardHeigth} order={1} boxShadow={"none"} radius={"4px"} backgroundImage={color ? color : bg} type={"junior"}>
                <MinigameCard done={juniorStatus} type={"junior"} ></MinigameCard>
            </Card>
        </div>
    )
}

export default CollectionCard